import { useRef, useState } from 'react';
import styles from './FooterSection.module.scss';
import { Link } from 'react-router-dom';
import { FacebookMessengerShareButton, FacebookShareButton, LineShareButton, TwitterShareButton } from 'react-share';
import handleGAevent from '../../helper/analytic';
import ReactPixel from 'react-facebook-pixel';
import ImageButton from '../../components/Button';
import { FACEBOOK_APP_ID } from '../../consts/facebookData';

const FooterSection = ({ cdn, sectionRef }) => {

    const closeBtn = 'assets/btn-close-type-2.png';
    const [isSharePopUpShow, setIsSharePopUpShow] = useState(false);
    const urlRef = useRef(null);
    const inviteUrl = new URL(window.location.href);
    inviteUrl.search = '';

    const titleToShare = 'ทัพของเราต้องการคุณ เข้าร่วมเพื่อรับรางวัล x10';
    const hashtag = ['#samkokorigin'];

    const shareButtonStyle = {
        width: '100%',
    }


    const copyButtonStyle = {
        width: '10%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '3%'
    }


    const openFacebookPage = () => {
        const facebookPageLink = 'https://www.facebook.com/Samkok.Origin';
        window.open(facebookPageLink, '_blank');
    };

    const openFacebookGroup = () => {
        const facebookGroupLink = 'https://facebook.com/groups/samkokoriginofficialgroup/';
        window.open(facebookGroupLink, '_blank');
    };

    const openDiscord = () => {
        const facebookGroupLink = 'https://discord.com/invite/WPnNde24Vc';
        window.open(facebookGroupLink, '_blank');
    };

    const clicklShare = () => {
        const facebookGroupLink = 'https://discord.com/invite/WPnNde24Vc';
        window.open(facebookGroupLink, '_blank');
    };

    const handleCopyText = async (valueToCopy) => {
        handleGAevent('Button', 'Click_Copy_Text', `Handle Copy Text`);
        ReactPixel.trackCustom('ClickCopyText');
        try {
            await navigator.clipboard.writeText(valueToCopy);
        } catch (error) {
            console.error('Error copying text to clipboard:', error);
        }
    };

    const handleOpenPopup = () => {
        setIsSharePopUpShow(true);
    };


    const handleClosePopup = () => {
        setIsSharePopUpShow(false);
    };


    return (
        <div className={styles.footer} ref={sectionRef}>
            <div className={`overlayBackground ${isSharePopUpShow ? 'show' : ''}`}>

                <div className={styles.popUpContainer}>
                    <img src={`${closeBtn}`} alt='close-btn' className={styles.closeBtn} onClick={handleClosePopup} />
                    <div className={styles.headText}>Share</div>
                    <div className={styles.shareBtnContainer}>
                        <FacebookShareButton url={inviteUrl} className={styles.shareBtn} quote={titleToShare} >
                            <ImageButton iconSrc="FB-2.png" altText="Icon" additionalButtonStyle={shareButtonStyle} />
                        </FacebookShareButton>
                        <FacebookMessengerShareButton appId={FACEBOOK_APP_ID} url={inviteUrl} className={styles.shareBtn}>
                            <ImageButton iconSrc="messenger.png" altText="Icon" additionalButtonStyle={shareButtonStyle} />
                        </FacebookMessengerShareButton>
                        <TwitterShareButton url={inviteUrl} title={titleToShare} hashtags={hashtag} className={styles.shareBtn} >
                            <ImageButton iconSrc="X.png" altText="Icon" additionalButtonStyle={shareButtonStyle} />
                        </TwitterShareButton>

                        <LineShareButton url={inviteUrl} title={titleToShare} className={styles.shareBtn} >
                            <ImageButton iconSrc="Line.png" altText="Icon" additionalButtonStyle={shareButtonStyle} />
                        </LineShareButton>
                    </div>
                    <div className={styles.shareBtnTitleContainer}>
                        <div className={styles.shareBtnTitle}>Facebook</div>
                        <div className={styles.shareBtnTitle}>Messenger</div>
                        <div className={styles.shareBtnTitle}>X</div>
                        <div className={styles.shareBtnTitle}>Line</div>
                    </div>

                    <div className={styles.disabledInputContainer}>
                        <input ref={urlRef} className={` ${styles.disableInputStyle}`} value={inviteUrl} disabled />
                        <ImageButton onClick={() => handleCopyText(inviteUrl)} iconSrc="btn_copy.png" altText="Icon" additionalButtonStyle={copyButtonStyle} />
                    </div>

                </div>

            </div>
            <div className={styles.footerIconGroup}>
                <img src={`${cdn}btn-share.png`} alt="" className={styles.footerIcon} onClick={handleOpenPopup} />
                <img src={`${cdn}btn-fb.png`} alt="" className={styles.footerIcon} onClick={openFacebookPage} />
                <img src={`${cdn}btn-fb-group.png`} alt="" className={styles.footerIcon} onClick={openFacebookGroup} />
                <img src={`${cdn}btn-discord.png`} alt="" className={styles.footerIcon} onClick={openDiscord} />
            </div>
            <img src={`https://sko-cdn.zoltangames.com/preregister/zoltan_logo.png`} alt="" className={styles.footerLogo} />
            <div className={styles.footerCopyright}>
                © Zoltan Games. All Rights Reserved.
            </div>
            <div className={styles.footerMenu}>

                <Link to="/privacy-policy" className={styles.footerLink}>นโยบายความเป็นส่วนตัว</Link>

                <span className={styles.footerSeparator}> | </span>

                <Link to="/term-of-service" className={styles.footerLink}>เงื่อนไขการให้บริการ</Link>

                <span className={styles.footerSeparator}> | </span>

                <Link to="/data-deletion-instruction" className={styles.footerLink}>ประกาศนโยบายการลบข้อมูลส่วนบุคคล</Link>

            </div>
        </div>
    )
}

export default FooterSection;