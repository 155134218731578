import ReactGA from 'react-ga4';

// Initialize Google Analytics
ReactGA.initialize('G-GW10W5K70M');

// Function to handle Google Analytics events
const handleGAevent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

// Export the handleGAevent function
export default handleGAevent;