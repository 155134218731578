import React from 'react';

const ImageButton = ({ className, onClick, iconSrc, altText, iconStyle, additionalButtonStyle , children}) => {
  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    padding: 0,
    position: 'relative'
  };

  const imageStyle = {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    maxHeight :'100%',
    objectFit: 'contains',
    ...iconStyle,
  };
  const combinedStyle = { ...buttonStyle, ...additionalButtonStyle };
  return (
    <button className={className} style={combinedStyle} onClick={onClick}>
      <img src={`https://sko-cdn.zoltangames.com/preregister/${iconSrc}`}  alt={altText} style={imageStyle}/>
      {children && <div style={{ position: 'absolute', top: 0, left: 0 ,width : '100%', height : '100%'}}>{children}</div>}
    </button>
  );
};

export default ImageButton;