const DataDeletionContent = ({ cdn, subtitle, paragraph, data_deletion }) => {
  const imageDataDeletion1 = cdn + 'img-data-deletion-1.png';
  const imageDataDeletion2 = cdn + 'img-data-deletion-2.png';
  const imageDataDeletion3 = cdn + 'img-data-deletion-3.png';
  const imageDataDeletion4 = cdn + 'img-data-deletion-4.png';

  return (
    <>
      <p className={paragraph}>
        ประกาศฉบับนี้บังคับใช้กับผู้เล่นเกม ของZoltanGames Company Limited และบริษัทในกลุ่มและบริษัทในเครือ (ซึ่งต่อไปนี้โดยเอกเทศและรวมกันเรียกว่า “ZoltanGames”, “เรา”, “พวกเรา” “บริษัท”หรือ “ของเรา”) ผู้เล่นเกมได้โปรดอ่านเงื่อนไขของประกาศฉบับนี้โดยละเอียด
      </p>
      <br/>
      <p className={paragraph}>
        <span className={subtitle}>1. ข้อมูลที่บริษัทเก็บรวบรวม</span>
        <br/><br/>
        1.1 ข้อมูลผู้เล่นเกม
        <br/>
        เมื่อท่านลงทะเบียนใช้บริการของบริษัท บริษัทไม่ได้เก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน ซึ่งรวมถึงแต่ไม่จำกัดเพียง
        <br/>
        • ชื่อ – นามสกุล
        <br/>
        • ที่อยู่ ทะเบียนบ้าน รายละเอียดภูมิลำเนา
        <br/>
        • บัตรประชาชน หรือหนังสือเดินทาง
        <br/>
        • IP Address
        <br/>
        • หมายเลขโทรศัพท์มือถือ
        <br/>
        • อีเมล
        <br/>
        • ข้อมูลการชำระเงิน
        <br/>
        • เพศ
        <br/>
        • อายุ
        <br/>
        • รูปภาพโปรไฟล์
        <br/>
        • ข้อมูลอุปกรณ์ที่ท่านใช้ในการซื้อผลิตภัณฑ์หรือบริการจากบริษัท
      </p>
      <br/>
      <p className={paragraph}>
        <span className={subtitle}>2. การลบข้อมูลส่วนบุคคล</span>
        <br/>
        เมื่อท่านต้องการลบข้อมูลเกี่ยวข้องกับบัญชีที่ใช้เล่นเกม ซึ่งรวมถึงแต่ไม่จำกัดเพียง เลเวล, ชื่อ, รหัสประจำตัวผู้เล่น, ข้อมูลการชำระเงิน และการใช้จ่ายที่เกี่ยวข้อง เมื่อทำการลบข้อมูลจากระบบเสร็จสมบูรณ์แล้ว บริษัทขอแจ้งให้ทราบว่า ไม่สามารถกู้คืนบัญชี ที่กล่าวไว้ข้างต้นได้ ถ้าท่านยืนยันที่จะลบบัญชีที่ใช้เล่นเกมอย่างถาวร สามารถทำได้โดย
        <br/><br/>
        2.1 เมื่อเข้าสู่เกมในหน้าหลัก ให้เลือกที่รูปโปรไฟล์
        <br/><br/>
        <img className={data_deletion} src={`${imageDataDeletion1}`} alt={'data-deletion-1'} />
        <br/><br/>
        2.2 ในหน้าต่างข้อมูลส่วนตัว ให้เลือกเมนู ลบบัญชี
        <br/><br/>
        <img className={data_deletion} src={`${imageDataDeletion2}`} alt={'data-deletion-2'} />
        <br/><br/>
        2.3 ยืนยันการลบบัญชี (ข้อมูลทั้งหมดของบัญชีนี้จะไม่สามารถกู้คืนได้)
        <br/><br/>
        <img className={data_deletion} src={`${imageDataDeletion3}`} alt={'data-deletion-3'} />
        <br/><br/>
        2.4 หลังจากยืนยันการลบบัญชีแล้ว ระบบจะให้ท่านเข้าสู่ระบบใหม่อีกครั้ง และจะไม่สามารถใช้ข้อมูลบัญชีเดิมได้อีก
        <br/><br/>
        <img className={data_deletion} src={`${imageDataDeletion4}`} alt={'data-deletion-4'} />
        <br/><br/>
        ฉบับปรับปรุงวันที่ 8 ธันวาคม 2566
      </p>
    </>
  );
}

export default DataDeletionContent;
