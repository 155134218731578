import React from 'react';
import './OverlayMenu.scss';
import ImageButton from './Button';
import handleGAevent from '../helper/analytic';
import ReactPixel from 'react-facebook-pixel';

const OverlayMenu = ({ showMenu, handleScrollToTop, overlayRef }) => {
  const handleAppStore = () => {
    handleGAevent('Overlay-menu', 'Click_Appstore_Badge', `Click Appstore Badge`);
    ReactPixel.trackCustom('ClickAppstoreBadge');
    const appStorePageLink = 'https://apps.apple.com/app/samkok-origin/id6469506005';
    window.open(appStorePageLink, '_blank');
  };

  const handleGooglePlayStore = () => {
    handleGAevent('Overlay-menu', 'Click_Playstore_Badge', `Click Google Playstore Badge`);
    ReactPixel.trackCustom('ClickPlaystoreBadge');
    const playStorePageLink = 'https://play.google.com/store/apps/details?id=com.ztg.skorigin.aos';
    window.open(playStorePageLink, '_blank');
  }

  // const overlayButtonUrl = `https://sko-cdn.zoltangames.com/preregister/overlay_menu_3.png`;
  const overlayButtonUrl = `https://sko-cdn.zoltangames.com/web/btn-overlay-store-v2.png`;
  const overlayMenuButtonStyle = {
    width: '100%',
    display: 'none'
  };

  const badgeButtonStyle2 = {
    marginTop: '10%',
    width: '20%',
  };

  return (
    <div className={`overlay-menu  ${showMenu ? 'visible' : ''}`} ref={overlayRef}>
      <div className="imgContainer">
        <img src={`${overlayButtonUrl}`} alt={'logo'} style={overlayMenuButtonStyle} />
        {/* <div className="marker1" onClick={handleAppStore} /> */}
        {/* <div className="marker2" onClick={handleGooglePlayStore} /> */}
      </div>
      <ImageButton onClick={handleScrollToTop} iconSrc="btn-scroll-top.png" altText="Icon" additionalButtonStyle={badgeButtonStyle2} />
    </div>

  );
};

export default OverlayMenu;