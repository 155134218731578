import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import styles from './IconVideoPlayer.module.scss'; // Import the SCSS file

const IconVideoPlayer = ({ cdn, videoSrc, thumbnailSrc, thumbnailWidth, thumbnailHeight, isPC = true }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);
  const btnClose = cdn + 'btn-close.png';

  const handlePlay = () => {
    if (!isPlaying) {
      setIsPlaying(true);
    }
  };

  const handleClose = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    if (isPlaying) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible"
    }
  }, [isPlaying])

  const containerStyle = {
    width: `${thumbnailWidth}`,
    height: `${thumbnailHeight}`,
  };

  return (
    <div id="video" className={`${styles.videoContainer} ${isPlaying ? styles.playing : ''}`} onClick={handlePlay} style={containerStyle}>
      
      {!isPlaying && (
        <div className={styles['thumbnailContainer']}>
          <img src={thumbnailSrc} alt="Video Thumbnail" className={styles.thumbnail} />
        </div>
      )}
      <div className={`${styles.player} ${isPlaying ? styles.playing : ''}`}>
        {isPlaying && (
          <img className={styles.btnClose} src={btnClose} alt='btn-close' onClick={handleClose}/>
        )}
        <ReactPlayer
          ref={playerRef}
          url={videoSrc}
          playing={isPlaying}
          controls
          width={isPC ? '100%' : '100%'}
          height={isPC ? '100%' : '100%'}
        />
      </div>
    </div>
  );
};

export default IconVideoPlayer;
