export const CHARACTER_DATA = [
    {
      id: 0, name: 'Cáo Cāo', iconUrl: 'img-cao-cao-icon.png', summaryHeader: "โจโฉ", subHeader : 'ผู้ไม่ยอมให้โลกทรยศ',
      flagUrl : 'https://sko-cdn.zoltangames.com/preregister/C%C3%A1o%20C%C4%81o_flag_Mobile.png', 
      videoThumbnailUrl : 'https://sko-cdn.zoltangames.com/preregister/img-cao-cao-vdo.png',
      videoUrl : 'https://youtu.be/5JmiD7ee6UM', 
      summary: "ผู้ปกครองที่ชาญฉลาดและเป็นนายทัพที่ปรีชาสามารถแห่งวุยก๊ก ถือเป็นหนึ่งในอัจฉริยะทางกลศึก และนักรบที่น่าเกรงขาม สามารถปราบเจ้าผู้ครองแคว้นต่าง ๆ ได้เกือบหมด ถือว่าเป็นหนึ่งในขุนศึกที่ได้รับการยอมรับในความสามารถมากที่สุดในแผ่นดิน",
      thumbnail: "https://sko-cdn.zoltangames.com/preregister/OG-2.jpg",
      description: "โหวตปั๊บ รอรับเลย!! ขุนพล SSR สุดแกร่ง ใน Sammkok Origin"
    },
    {
      id: 1, name: 'Zhuge Liang', iconUrl: 'img-zhuge-liang-icon.png', summaryHeader: "ขงเบ้ง",subHeader : 'ผู้หยั่งรู้ดินฟ้ามหาสมุทร',
      flagUrl : 'https://sko-cdn.zoltangames.com/preregister/Zhuge%20Liang_flag_Mobile.png', 
      videoThumbnailUrl : 'https://sko-cdn.zoltangames.com/preregister/img-zhuge-liang-vdo.png',
      videoUrl : 'https://youtu.be/Fw99Oi4Pexo',
      summary: "สมุหนายกและผู้บัญชาการทหารสูงสุดของพระเจ้าเล่าปี่ ผู้วางแผนกลศึกที่ใคร ๆ ต่างต้องหวาดกลัวในฝีมือและมันสมอง ด้วยนิสัยที่มีความยุติธรรม และความขยันขันแข็งจึงได้รับการยอมรับว่าเป็นหนึ่งในขุนศึกที่ทรงพลังที่สุดในแผ่นดิน",
      thumbnail: "https://sko-cdn.zoltangames.com/preregister/OG-3.jpg",
      description: "โหวตปั๊บ รอรับเลย!! ขุนพล SSR สุดแกร่ง ใน Sammkok Origin"
    },
    {
      id: 2, name: 'Guan Yu', iconUrl: 'img-guan-ya-icon.png', summaryHeader: "กวนอู",subHeader : 'ยอดขุนพลแห่งความซื่อสัตย์',
      flagUrl : 'https://sko-cdn.zoltangames.com/preregister/Guan%20Yu_flag_Mobile.png', 
      videoThumbnailUrl : 'https://sko-cdn.zoltangames.com/preregister/img-guan-ya-vdo.png',
      videoUrl: 'https://youtu.be/nDk3QAqQa9o',
      summary: "หนึ่งในขุนศึกผู้มีฝีมือเก่งกาจ เป็นที่เลื่องลือกล่าวขานทั่วทั้งแผ่นดินจำนวน 5 คนของพระเจ้าเล่าปี่ มาพร้อมสุดยอดอาวุธง้าวมังกรเขียวสุดทรงพลัง ขึ้นชื่อในเรื่องของคุณธรรมและความเชี่ยวชาญในการทำศึกสงคราม",
      thumbnail: "https://sko-cdn.zoltangames.com/preregister/OG-1.jpg",
      description: "โหวตปั๊บ รอรับเลย!! ขุนพล SSR สุดแกร่ง ใน Sammkok Origin"
    },
    {
      id: 3, name: 'lubu', iconUrl: 'img-lubu-icon.png', summaryHeader: "ลิโป้",subHeader : 'เทพสงครามแห่งยุคสามก๊ก',
      flagUrl : 'https://sko-cdn.zoltangames.com/preregister/Lubu_flag_Mobile.png', 
      videoThumbnailUrl : 'https://sko-cdn.zoltangames.com/preregister/img-lubu-vdo.png',
      videoUrl : 'https://youtu.be/kTXR1svsSeM',
      summary: "ลิโป้ ชายผู้หนึ่งผู้ที่กำราบบรรดายอดฝีมือจนได้ชื่อว่าแข็งแกร่งที่สุดในใต้หล้า สามารถยิงเกาทัณฑ์ได้แม่นยำเหมือนจับวาง ตราบใดที่เขายังถือทวนกรีดนภา และนั่งอยู่บนหลังม้าเซ็กเธาว์ ก็ไม่มีใครล้มเขาลงได้",
      thumbnail: "https://sko-cdn.zoltangames.com/preregister/OG-4.jpg",
      description: "โหวตปั๊บ รอรับเลย!! ขุนพล SSR สุดแกร่ง ใน Sammkok Origin"
    },
  ];