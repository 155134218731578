import IconVideoPlayer from '../../components/IconVideoPlayer';

import styles from './Section5.module.scss';

const Section5 = ({ cdn, sectionRef, isMobile, contentWidth }) =>  {
  const imageSection5 = cdn + 'img-section-5' + (isMobile ? '.jpg' : '-tablet.svg');
  const btnPlay = cdn + 'btn-play-system.png';
  return (
    <div className={styles.section} ref={sectionRef}>
      <img className={styles.bg} src={`${imageSection5}`} alt={'section-5'} />
      <div className={`${styles.btnPlay} ${styles.system1}`}>
        <IconVideoPlayer
          cdn={cdn}
          videoSrc={'https://youtu.be/pXpIo-bYHz0'}
          thumbnailSrc={`${btnPlay}`}
          thumbnailWidth={`${contentWidth * (isMobile ? 30 : 20) / 100}px`}
          thumbnailHeight={`${contentWidth * (isMobile ? 30 : 20) / 100}px`}
          isPC={false} 
        />
      </div>
      <div className={`${styles.btnPlay} ${styles.system2}`}>
        <IconVideoPlayer
          cdn={cdn}
          videoSrc={'https://youtu.be/lH_JLV0Jgis'}
          thumbnailSrc={`${btnPlay}`}
          thumbnailWidth={`${contentWidth * (isMobile ? 30 : 20) / 100}px`}
          thumbnailHeight={`${contentWidth * (isMobile ? 30 : 20) / 100}px`}
          isPC={false} 
        />
      </div>
      <div className={`${styles.btnPlay} ${styles.system3}`}>
        <IconVideoPlayer
          cdn={cdn}
          videoSrc={'https://youtu.be/h_pdRoXCVho'}
          thumbnailSrc={`${btnPlay}`}
          thumbnailWidth={`${contentWidth * (isMobile ? 30 : 20) / 100}px`}
          thumbnailHeight={`${contentWidth * (isMobile ? 30 : 20) / 100}px`}
          isPC={false} 
        />
      </div>
      <div className={`${styles.btnPlay} ${styles.system4}`}>
        <IconVideoPlayer
          cdn={cdn}
          videoSrc={'https://youtu.be/veOvGaeX2KM'}
          thumbnailSrc={`${btnPlay}`}
          thumbnailWidth={`${contentWidth * (isMobile ? 30 : 20) / 100}px`}
          thumbnailHeight={`${contentWidth * (isMobile ? 30 : 20) / 100}px`}
          isPC={false} 
        />
      </div>
    </div>
  )
}

export default Section5;
