import { useState } from 'react';
import ReactPixel from 'react-facebook-pixel';

import PopUpMobile from '../../components/PopUpMobile';
import handleGAevent from '../..//helper/analytic';
import PopUpPreRegister from './PopUpPreRegister';

import styles from './Section3.module.scss';

const Section3 = ({ cdn, sectionRef, isMobile, fetchDataReward, email, setEmail, rewardAccepted, setRewardAccepted, isTeamCreator, setIsTeamCreator }) => {
  const imgSection3 = cdn + 'img-section-3' + (isMobile ? '.jpg' : '-tablet.svg');
  const imgInviteFriend1 = cdn + 'img-invite-friend-1.png';
  const imgInviteFriend2 = cdn + 'img-invite-friend-2.png';
  const imgInviteFriend3 = cdn + 'img-invite-friend-3.png';
  const imgInviteFriend4 = cdn + 'img-invite-friend-4.png';
  const imgInviteFriendChecked = cdn + 'img-invite-friend-checked.png';
  const btnRegister = cdn + 'btn-register.png';
  const btnInfo = cdn + 'btn-info.png';

  const [isPreRegisPageShow, setIsPreRegisPageShow] = useState(false);

  // -- Info button function
  const handleInfoPopupOpen = () => {
    handleGAevent('Page-4', 'Open_Info_Pop_Up_Page_4', `Open Info Pop Up Page 4`);
    ReactPixel.trackCustom('OpenInfoPopUpPage4');
    window.open('/event-detail-4', '_blank');
  }

  const handleRegisMenuShowOpen = () => {
    handleGAevent('Page-Mobile-1', 'Click_Join_Invite_Friend_Event', 'Click Join Invite Friend Event');
    ReactPixel.trackCustom('ClickJoinInviteFriendEvent');
    setIsPreRegisPageShow(true);
  }

  const handleRegisMenuShowClose = () => {
    handleGAevent('Page-Mobile-1', 'Close_PreRegis_Page', 'Click Close Pre-register');
    ReactPixel.trackCustom('ClosePreRegisPage');
    setIsPreRegisPageShow(false);
  }

  return (
    <div className={styles.section} ref={sectionRef}>
      <img className={styles.bg} src={`${imgSection3}`} alt={'section-3'} loading="lazy" />
      <img className={`${styles.imgInvite} ${styles.inviteFriend1}`} src={`${imgInviteFriend1}`} alt={'img-invite-friend-1'} loading="lazy" />
      <img className={`${styles.imgInvite} ${styles.inviteFriend2}`} src={`${imgInviteFriend2}`} alt={'img-invite-friend-2'} loading="lazy" />
      <img className={`${styles.imgInvite} ${styles.inviteFriend3}`} src={`${imgInviteFriend3}`} alt={'img-invite-friend-3'} loading="lazy" />
      <img className={`${styles.imgInvite} ${styles.inviteFriend4}`} src={`${imgInviteFriend4}`} alt={'img-invite-friend-4'} loading="lazy" />
      <img className={`${styles.imgInvite} ${styles.inviteFriend1} ${styles.none} ${(1 <= rewardAccepted) ? styles.checked : ''}`} src={`${imgInviteFriendChecked}`} alt={'img-invite-friend-1-checked'} loading="lazy" />
      <img className={`${styles.imgInvite} ${styles.inviteFriend2} ${styles.none} ${(2 <= rewardAccepted) ? styles.checked : ''}`} src={`${imgInviteFriendChecked}`} alt={'img-invite-friend-2-checked'} loading="lazy" />
      <img className={`${styles.imgInvite} ${styles.inviteFriend3} ${styles.none} ${(3 <= rewardAccepted) ? styles.checked : ''}`} src={`${imgInviteFriendChecked}`} alt={'img-invite-friend-3-checked'} loading="lazy" />
      <img className={`${styles.imgInvite} ${styles.inviteFriend4} ${styles.none} ${(4 <= rewardAccepted) ? styles.checked : ''}`} src={`${imgInviteFriendChecked}`} alt={'img-invite-friend-4-checked'} loading="lazy" />
      <img className={styles.btnRegister} src={`${btnRegister}`} alt={'btn-register'} loading="lazy" onClick={handleRegisMenuShowOpen} />
      <img className={styles.btnInfo} onClick={handleInfoPopupOpen} src={`${btnInfo}`} alt={'Icon-info'} loading="lazy" />

      <PopUpMobile cdn={cdn} isShow={isPreRegisPageShow} handleClosePopup={handleRegisMenuShowClose}>
        <PopUpPreRegister
          cdn={cdn}
          email={email}
          setEmail={setEmail}
          fetchDataReward={fetchDataReward}
          rewardAccepted={rewardAccepted}
          setRewardAccepted={setRewardAccepted}
          isTeamCreator={isTeamCreator}
          setIsTeamCreator={setIsTeamCreator}
          handleRegisMenuShowClose={handleRegisMenuShowClose} />
      </PopUpMobile>
    </div>
  )
}

export default Section3;
