import React, { useState,useRef } from 'react';
import styles from './PopUpFullTeam.module.scss'

const PopUpFullTeam = ({ cdn , isShow}) => {

  const handleTeamFull = () => {
    const newUrl = new URL(window.location.href);
    newUrl.search = '';
    window.location.href = newUrl.href;
  };

  const imgPopup1 = cdn + 'img-pop-up-fullteam.png'; 
  return (
    <div className= {`${styles.overlayBackground} ${isShow ? `${styles.show}` : ''}`}>
        <div className={`${styles.popOutContainer}`}>
          <div className={`${styles.imgPopup}`} >
            <img className={`${styles.img}`} src={`${imgPopup1}`} alt={'img-popup-1'} />
            <div className={`${styles.clickableArea} ${styles.position1}`} onClick={handleTeamFull} ></div>
            <div className={`${styles.clickableArea} ${styles.position2}`} onClick={handleTeamFull} ></div>
          </div>
        </div>
    </div>

  );
};

export default PopUpFullTeam;