import React from 'react';
import { useNavigate } from "react-router-dom";

import ContentScrollbar from '../../components/ContentScrollbar';
import DataDeletionContent from '../../components/DataDeletionContent';
import styles from './TermOfServiceLayoutMobile.module.scss';

const DataDeletionLayoutMobile = ({ cdn }) => {
	const imageUrl = cdn + 'logo.png';
  
  const navigate = useNavigate();

	const handleClick = () => {
		navigate("/");
	}

	return (
    <div>
			<div className={styles.background} />
			<div className={styles.shadow} />
			<div className={styles.header}>
        <img className={styles.logo} src={`${imageUrl}`} alt={'logo'} onClick={() => handleClick()} />
				<div className={styles.title}>
          ประกาศนโยบายการลบข้อมูลส่วนบุคคล
				</div>
			</div>
			<div className={styles.container}>
        <ContentScrollbar isMobile={true} >
          <DataDeletionContent cdn={cdn} subtitle={styles.subtitle} paragraph={styles.paragraph} data_deletion={styles.data_deletion} />
        </ContentScrollbar>
			</div>
    </div>
  );
}

export default DataDeletionLayoutMobile;