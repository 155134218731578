
import styles from './WarningLayout.module.scss';

const WarningLayout = ({ cdn }) => {
  return (
    <div className={styles.warningPage}>
        <div className={styles.warningContent}>
          <img className={styles.warningImg} src={cdn + 'smartphone_rotate.png'} alt="header" />
          <span className={styles.warningTxt}>กรุณาหมุนอุปกรณ์ของคุณ</span>
        </div>
    </div>
  );
}

export default WarningLayout;
