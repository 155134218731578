import React, { useState, useRef, useEffect } from 'react';
import styles from './PopUpMobile.module.scss';

const PopUpMobile = ({ cdn, children , isShow, handleClosePopup, containerStyle}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);
  
  const btnPreRegister = cdn + 'btn-close-pre-register.png';

  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible"
    }
  }, [isShow])

  const buttonStyle = {
    width : '5%',
    position : 'absolute',
    top : '6%',
    right : '1%'
  };
  const handlePlay = () => {
    if (!isPlaying) {
        setIsPlaying(true);
    }
  };

  const handleClose = () => {
    setIsPlaying(false);
  };

  return (
    <div className={`${styles.overlayBackground} ${isShow ? styles.show : ''}`}>
      <div className={styles.popOutContainer}>
        <img className={styles.btnClose} src={`${btnPreRegister}`} alt={'btn-pre-register'} onClick={handleClosePopup} />
        {/* <ImageButton  
          onClick={handleClosePopup} 
          iconSrc="btn_close_dark.png" 
          altText="Icon" 
          additionalButtonStyle={buttonStyle} 
        /> */}
        <div className={styles.popUpContent} style={containerStyle}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PopUpMobile;