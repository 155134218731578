const NoMatchLayout = () =>  {
  
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    fontFamily: 'Noto Sans Thai',
    fontSize: '20rem',
    fontWeight: '900',
    lineHeight: 'auto',
  }

  return (
    <div style={style}>
      404
    </div>
  )
}

export default NoMatchLayout;
