import { useState } from 'react';
import ReactPixel from 'react-facebook-pixel';

import IconVideoPlayer from '../../components/IconVideoPlayer';
import PopUpMobile from '../../components/PopUpMobile';
import handleGAevent from '../..//helper/analytic';

import PopUpPreRegister from './PopUpPreRegister';

import styles from './Section1.module.scss';

const Section1 = ({ cdn, sectionRef, isMobile, contentWidth, fetchDataReward, preRegisCount, email,setEmail, rewardAccepted, setRewardAccepted, isTeamCreator, setIsTeamCreator }) =>  {
  const imgSection1 = cdn + 'img-section-1' + (isMobile ? '.jpg' : '-tablet.png');
  const txtRegister = cdn + 'img-txt-section-1.svg';
  const btnPlay = cdn + 'btn-play.png';
  const btnRegister = cdn + 'btn-pre-register.png';

  const [isPreRegisPageShow, setIsPreRegisPageShow] = useState(false);

  const handleRegisMenuShowOpen = () => {
    handleGAevent('Page-Mobile-1', 'Click_Pre-register', 'Click Pre-register');
    ReactPixel.trackCustom('OpenPreRegisPage');
    setIsPreRegisPageShow(true);
  }

  const handleRegisMenuShowClose = () => {
    handleGAevent('Page-Mobile-1', 'Close_PreRegis_Page', 'Click Close Pre-register');
    ReactPixel.trackCustom('ClosePreRegisPage');
    setIsPreRegisPageShow(false);
  }

  return (
    <div className={styles.section} ref={sectionRef}>
      <img className={styles.bg} src={`${imgSection1}`} alt={'section-1'} />
      <div className={styles.btnPlay}>
        <IconVideoPlayer
            cdn={cdn}
            videoSrc={'https://youtu.be/MBPUEOQnkbE'}
            thumbnailSrc={`${btnPlay}`}
            thumbnailWidth={`${contentWidth * (isMobile ? 38.14 : 23.63) / 100}px`}
            thumbnailHeight={`${contentWidth * (isMobile ? 38.14 : 23.63) / 100}px`}
            isPC={false} 
        />
      </div>
      <div className={styles.textContainer}>
          <img className={styles.txtRegister} src={`${txtRegister}`} alt={'txt-section-1'} />
          <div className={styles.txtCount}>{preRegisCount.toLocaleString()}</div>
      </div>
      <img className={styles.btnRegister} src={`${btnRegister}`} alt={'btn-register'} onClick={handleRegisMenuShowOpen} />
      <PopUpMobile cdn={cdn} isShow={isPreRegisPageShow} handleClosePopup={handleRegisMenuShowClose}>
        <PopUpPreRegister 
          cdn={cdn}
          email={email}
          setEmail={setEmail} 
          fetchDataReward={fetchDataReward} 
          rewardAccepted={rewardAccepted} 
          setRewardAccepted={setRewardAccepted}
          isTeamCreator={isTeamCreator}
          setIsTeamCreator={setIsTeamCreator} 
          handleRegisMenuShowClose={handleRegisMenuShowClose} />
      </PopUpMobile>
    </div>
  )
}

export default Section1;
