
import styles from './PopUpPreRegisterReward.module.scss';

const PopUpPreRegisterReward = ({ cdn, rewardAccepted }) => Array.from({ length: 4 }).map((_, index) => {

  const checked = {
    opacity: (index + 1 <= rewardAccepted) ? '0.8' : '0'
  };

  const textArray = [
    <div><span>สร้างรหัส</span><br/><span>ชวนเพื่อน</span></div>,
    <div><span>เชิญเพื่อน</span><br/><span>สำเร็จ 1 คน</span></div>,
    <div><span>เชิญเพื่อน</span><br/><span>สำเร็จ 2 คน</span></div>,
    <div><span>เชิญเพื่อน</span><br/><span>สำเร็จ 3 คน</span></div>,
  ]

  const imgArray = [
    cdn + 'img-invite-friend-1.png',
    cdn + 'img-invite-friend-2.png',
    cdn + 'img-invite-friend-3.png',
    cdn + 'img-invite-friend-4.png',
    cdn + 'img-invite-friend-checked.png'
  ]

  return (
    <div key={index} className={styles.rewardPlaceholder} >
      <div className={styles.rewardContainer}>
        <div className={styles.rewardContainerImg}>
          <img className={`${styles.imgReward} ${styles.checked}`} src={`${imgArray[4]}`} alt={'btn-reward-accept'} style={checked} />
          {/* <ImageButton
            iconSrc="reward_accept.png"
            altText="Icon"
            additionalButtonStyle={overlayStyle}
          /> */}
          <img className={`${styles.imgReward} ${styles.base}`} src={`${imgArray[index]}`} alt={'btn-reward'} />
          {/* <ImageButton
            iconSrc="reward-box.png"
            altText="Icon"
            iconStyle={{ height: '80%', width: '80%' }}
            additionalButtonStyle={dynamicStyle}
          /> */}
        </div>
      </div>
      <div className={styles.text}>
          {textArray[index]}
      </div>
    </div>
  );
})

export default PopUpPreRegisterReward;
