import styles from './EventDetailLayout.module.scss';

const EventDetailLayout = ({ cdn, isMobile, pageNumber }) =>  {
  const eventDetailImage = cdn + `img-event-details-v2-${isMobile ? 'mobile' : 'tablet'}-page-${pageNumber}.svg`; 

  return (
    <div className={styles.section}>
      <img className={styles.bg} src={`${eventDetailImage}`} alt={'event-detail'} />
    </div>
  )
}

export default EventDetailLayout;
