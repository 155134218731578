import React from 'react';
import styles from './FollowReward.module.scss'; 
import { useState } from 'react';
import ImageButton from './Button';

const FollowReward = ({ onClose , rewardText , rewardQuantity, infoText, iconUrl}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleHover = () => {
        setIsHovered(true);
    };

    const handleLeave = () => {
        setIsHovered(false);
    };  

    const followRewardStyle = {
        height: '80%',
    };

  return (
    <div className={styles.followReward}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
    >
        <div className={`${styles['hover-info']} ${isHovered ? styles['hovered'] : ''}`}>
            {infoText}
        </div>
      <ImageButton onClick={onClose} iconSrc="img-follow-reward.png" altText="Icon" additionalButtonStyle={followRewardStyle}>
          <img className={styles.gold} src={`https://sko-cdn.zoltangames.com/preregister/${iconUrl}`} alt="" />
      </ImageButton>
      <div className={`${styles.followRewardText}`}>
        {rewardText}
      </div>
      <div className={`${styles.followRewardText} ${styles.quantity}`}>
        {rewardQuantity}
      </div>
    </div>
  );
};

export default FollowReward;