import IconVideoPlayer from '../../components/IconVideoPlayer';

import styles from './Section4.module.scss';

const Section4 = ({ cdn, sectionRef, isMobile, contentWidth }) =>  {
  const imageSection41 = cdn + 'img-section-4-1' + (isMobile ? '.jpg' : '-tablet.svg');
  const imageSection42 = cdn + 'img-section-4-2' + (isMobile ? '.jpg' : '-tablet.svg');
  const imageSection43 = cdn + 'img-section-4-3' + (isMobile ? '.jpg' : '-tablet.svg');
  const imageSection44 = cdn + 'img-section-4-4' + (isMobile ? '.jpg' : '-tablet.svg');
  const btnVdo41 = cdn + 'btn-vdo-4-1.png';
  const btnVdo42 = cdn + 'btn-vdo-4-2.png';
  const btnVdo43 = cdn + 'btn-vdo-4-3.png';
  const btnVdo44 = cdn + 'btn-vdo-4-4.png';
  return (
    <>
      <div className={styles.section} ref={sectionRef}>
        <img className={styles.bg} src={`${imageSection41}`} alt={'section-4-1'} />
        <div className={styles.btnPlay}>
          <IconVideoPlayer
            cdn={cdn}
            videoSrc={'https://youtu.be/5JmiD7ee6UM'}
            thumbnailSrc={`${btnVdo41}`}
            thumbnailWidth={`${contentWidth * (isMobile ? 38.14 : 21.875) / 100}px`}
            thumbnailHeight={`${contentWidth * (isMobile ? 21.3 : 12.3) / 100}px`}
            isPC={false} 
          />
        </div>
      </div>
     <div className={styles.section}>
        <img className={styles.bg} src={`${imageSection42}`} alt={'section-4-2'} />
        <div className={styles.btnPlay}>
          <IconVideoPlayer
            cdn={cdn}
            videoSrc={'https://youtu.be/Fw99Oi4Pexo'}
            thumbnailSrc={`${btnVdo42}`}
            thumbnailWidth={`${contentWidth * (isMobile ? 38.14 : 21.875) / 100}px`}
            thumbnailHeight={`${contentWidth * (isMobile ? 21.3 : 12.3) / 100}px`}
            isPC={false} 
          />
        </div>
      </div>
      <div className={styles.section}>
        <img className={styles.bg} src={`${imageSection43}`} alt={'section-4-3'} />
        <div className={styles.btnPlay}>
          <IconVideoPlayer
            cdn={cdn}
            videoSrc={'https://youtu.be/kTXR1svsSeM'}
            thumbnailSrc={`${btnVdo43}`}
            thumbnailWidth={`${contentWidth * (isMobile ? 38.14 : 21.875) / 100}px`}
            thumbnailHeight={`${contentWidth * (isMobile ? 21.3 : 12.3) / 100}px`}
            isPC={false} 
          />
        </div>
      </div>
      <div className={styles.section}>
        <img className={styles.bg} src={`${imageSection44}`} alt={'section-4-4'} />
        <div className={styles.btnPlay}>
          <IconVideoPlayer
            cdn={cdn}
            videoSrc={'https://youtu.be/nDk3QAqQa9o'}
            thumbnailSrc={`${btnVdo44}`}
            thumbnailWidth={`${contentWidth * (isMobile ? 38.14 : 21.875) / 100}px`}
            thumbnailHeight={`${contentWidth * (isMobile ? 21.3 : 12.3) / 100}px`}
            isPC={false} 
          />
        </div>
      </div>
    </>
  )
}

export default Section4;
