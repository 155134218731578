import { useState, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

import handleGAevent from '../..//helper/analytic';
import apiHelper from '../../helper/apiHelper';

import styles from './Section2.module.scss';

const Section2 = ({ cdn, sectionRef, isMobile, preRegisCount }) => {
  const imgSection21 = cdn + 'img-section-2-1' + (isMobile ? '.jpg' : '-tablet.svg'); 
  const imgSection22 = cdn + 'img-section-2-2' + (isMobile ? '.jpg' : '-tablet.svg'); 
  const imgReward1 = cdn + 'img-reward-1.png'; 
  const imgReward2 = cdn + 'img-reward-2.png'; 
  const imgReward3 = cdn + 'img-reward-3.png'; 
  const imgReward4 = cdn + 'img-reward-4.png'; 
  const imgReward5 = cdn + 'img-reward-5-'; 
  const imgRewardChecked = cdn + 'img-reward-checked.png'; 
  const imgRewardFb1 = cdn + 'img-reward-fb-1.png'; 
  const imgRewardFb2 = cdn + 'img-reward-fb-2.png'; 
  const imgRewardFb3 = cdn + 'img-reward-fb-3.png'; 
  const imgRewardFb4 = cdn + 'img-reward-fb-4.png'; 
  const imgRewardFb5 = cdn + 'img-reward-fb-5.png'; 
  const imgRewardFb6 = cdn + 'img-reward-fb-6.png'; 
  const imgRewardFb7 = cdn + 'img-reward-fb-7.png'; 
  const imgRewardFb8 = cdn + 'img-reward-fb-8.png'; 
  const imgRewardFb9 = cdn + 'img-reward-fb-9.png'; 
  const imgRewardFb10 = cdn + 'img-reward-fb-10.png'; 
  const imgRewardFbChecked = cdn + 'img-reward-fb-checked.png'; 
  const btnFollowFb = cdn + 'btn-follow-fb.png'; 
  const btnJoinGroup = cdn + 'btn-join-group.png'; 
  const btnInfo = cdn + 'btn-info.png';

  const [followFb, setFollowFb] = useState(0);
  const [joinGroup, setJoinGroup] = useState(0);

  const [characterTimerValue, setCharacterTimerValue] = useState(0);
  const initialTimestamp = new Date();

  // -- Info button function
  const handleInfoPopupOpen = () => {
    handleGAevent('Page-2', 'Open_Info_Pop_Up_Page_2', `Open Info Pop Up Page 2`);
    ReactPixel.trackCustom('OpenInfoPopUpPage2');
    window.open('/event-detail-2', '_blank');
  }
  // -- Character countdown timer
  useEffect(() => {
    // Get the current date and time
    const currentDate = new Date();

    // Get the timestamp for 24 hours ago from the initial timestamp
    const twentyFourHoursAgo = new Date(initialTimestamp);
    twentyFourHoursAgo.setDate(twentyFourHoursAgo.getDate() + 1); // Add 1 day to get the timestamp for 24 hours ago

    // Check if the current date is after 24 hours ago
    const isAfter24Hours = currentDate > twentyFourHoursAgo;

    const handleTimer = () => {
      // If it's after 24 hours, exclude reward_7_3
      if (isAfter24Hours) {
        setCharacterTimerValue((prevValue) => (prevValue + 1) % 3);
      } else {
        setCharacterTimerValue((prevValue) => (prevValue + 1) % 4);
      }
    };

    const timerInterval = setInterval(handleTimer, 60000);

    return () => clearInterval(timerInterval);
  }, []);

  useEffect(() => {
    const fetchFollowFb = async () => {
      try {
        const body = { type: 1 };
        const result = await apiHelper('milestone/get-count', 'POST', body);
        const responseData = result.data.count;
        setFollowFb(responseData);
      } catch (error) {

      }
    };
    const fetchJoinGroup = async () => {
      try {
        const body = { type: 2 };
        const result = await apiHelper('milestone/get-count', 'POST', body);
        const responseData = result.data.count;
        setJoinGroup(responseData);
      } catch (error) {

      }
    };
    fetchFollowFb();
    fetchJoinGroup();
  }, []);

  const generateDeviceId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const handleClickFollowButton = (identifier) => {
    let hasClickedBefore;
    try {
      hasClickedBefore = localStorage.getItem('hasClicked ' + identifier);
    } catch (error) {
      console.error('Error accessing localStorage:', error);
    }
    if (!hasClickedBefore) {
      try {
        localStorage.setItem('hasClicked ' + identifier, 'true');
        const deviceId = localStorage.getItem('deviceId' + identifier);
        if (!deviceId) {
          const newDeviceId = generateDeviceId();
          localStorage.setItem('deviceId' + identifier, newDeviceId);
          const addCount = async (identifier) => {
            try {
              const body = { type: identifier }
              const result = await apiHelper('milestone/count', 'POST', body);
              const responseData = result.data.count;
              if (result.responseCode === 0) {
                if (identifier === 1) {
                  setFollowFb(() => responseData);
                } else {
                  setJoinGroup(() => responseData);
                }
              }
            } catch (error) {

            }
          };
          addCount(identifier);
        }
      } catch (error) {
        console.error('Error accessing localStorage:', error);
      }
    }
    if (identifier === 1) {
      handleGAevent('Page-Mobile-3', 'Click_Follow_Facebook_Fanpage', 'Click Follow Facebook Fanpage');
      ReactPixel.trackCustom('ClickFollowFacebookFanpage');
      const facebookPageLink = 'https://www.facebook.com/Samkok.Origin';
      window.open(facebookPageLink, '_blank');
    } else {
      handleGAevent('Page-Mobile-3', 'Click_Follow_Facebook_Group', 'Click Follow Facebook Official Group');
      ReactPixel.trackCustom('ClickFollowFacebookGroup');
      const discordInviteLink = 'https://facebook.com/groups/samkokoriginofficialgroup/';
      window.open(discordInviteLink, '_blank');
    }
  };

  return (
    <>
      <div className={styles.section} ref={sectionRef}>
        <img className={styles.bg} src={`${imgSection21}`} alt={'section-2-1'} />
        <img className={styles.btnInfo} onClick={handleInfoPopupOpen} src={`${btnInfo}`} altText="Icon-info" />
        <img className={`${styles.imgReward} ${styles.reward1}`} src={`${imgReward1}`} alt={'img-reward-1'} loading="lazy"/>
        <img className={`${styles.imgReward} ${styles.reward2}`} src={`${imgReward2}`} alt={'img-reward-2'} loading="lazy"/>
        <img className={`${styles.imgReward} ${styles.reward3}`} src={`${imgReward3}`} alt={'img-reward-3'} loading="lazy"/>
        <img className={`${styles.imgReward} ${styles.reward4}`} src={`${imgReward4}`} alt={'img-reward-4'} loading="lazy"/>
        <img className={`${styles.imgReward} ${styles.reward5}`} src={`${imgReward5}${characterTimerValue}.png`} alt={'img-reward-5'} loading="lazy"/>
        <img className={`${styles.imgReward} ${styles.reward1} ${styles.none} ${(50000 <= preRegisCount) ? styles.checked : ''}`} src={`${imgRewardChecked}`} alt={'img-reward-1-checked'}loading="lazy" />
        <img className={`${styles.imgReward} ${styles.reward2} ${styles.none} ${(100000 <= preRegisCount) ? styles.checked : ''}`} src={`${imgRewardChecked}`} alt={'img-reward-2-checked'} loading="lazy"/>
        <img className={`${styles.imgReward} ${styles.reward3} ${styles.none} ${(200000 <= preRegisCount) ? styles.checked : ''}`} src={`${imgRewardChecked}`} alt={'img-reward-3-checked'}loading="lazy" />
        <img className={`${styles.imgReward} ${styles.reward4} ${styles.none} ${(300000 <= preRegisCount) ? styles.checked : ''}`} src={`${imgRewardChecked}`} alt={'img-reward-4-checked'}loading="lazy" />
        <img className={`${styles.imgReward} ${styles.reward5} ${styles.none} ${(400000 <= preRegisCount) ? styles.checked : ''}`} src={`${imgRewardChecked}`} alt={'img-reward-5-checked'} loading="lazy"/>
      </div>
      <div className={styles.section}>
        <img className={styles.bg} src={`${imgSection22}`} alt={'section-2-2'} />
        <img className={`${styles.imgRewardFb} ${styles.rewardFb1}`} src={`${imgRewardFb1}`} alt={'img-reward-fb-1'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb2}`} src={`${imgRewardFb2}`} alt={'img-reward-fb-2'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb3}`} src={`${imgRewardFb3}`} alt={'img-reward-fb-3'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb4}`} src={`${imgRewardFb4}`} alt={'img-reward-fb-4'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb5}`} src={`${imgRewardFb5}`} alt={'img-reward-fb-5'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb6}`} src={`${imgRewardFb6}`} alt={'img-reward-fb-6'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb7}`} src={`${imgRewardFb7}`} alt={'img-reward-fb-7'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb8}`} src={`${imgRewardFb8}`} alt={'img-reward-fb-8'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb9}`} src={`${imgRewardFb9}`} alt={'img-reward-fb-9'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb10}`} src={`${imgRewardFb10}`} alt={'img-reward-fb-10'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb1} ${styles.none} ${(2500 <= followFb) ? styles.checked : ''}`} src={`${imgRewardFbChecked}`} alt={'img-reward-fb-1-checked'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb2} ${styles.none} ${(5000 <= followFb) ? styles.checked : ''}`} src={`${imgRewardFbChecked}`} alt={'img-reward-fb-2-checked'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb3} ${styles.none} ${(7500 <= followFb) ? styles.checked : ''}`} src={`${imgRewardFbChecked}`} alt={'img-reward-fb-3-checked'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb4} ${styles.none} ${(10000 <= followFb) ? styles.checked : ''}`} src={`${imgRewardFbChecked}`} alt={'img-reward-fb-4-checked'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb5} ${styles.none} ${(15000 <= followFb) ? styles.checked : ''}`} src={`${imgRewardFbChecked}`} alt={'img-reward-fb-5-checked'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb6} ${styles.none} ${(500 <= joinGroup) ? styles.checked : ''}`} src={`${imgRewardFbChecked}`} alt={'img-reward-fb-6-checked'}loading="lazy" />
        <img className={`${styles.imgRewardFb} ${styles.rewardFb7} ${styles.none} ${(1000 <= joinGroup) ? styles.checked : ''}`} src={`${imgRewardFbChecked}`} alt={'img-reward-fb-7-checked'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb8} ${styles.none} ${(2000 <= joinGroup) ? styles.checked : ''}`} src={`${imgRewardFbChecked}`} alt={'img-reward-fb-8-checked'}loading="lazy" />
        <img className={`${styles.imgRewardFb} ${styles.rewardFb9} ${styles.none} ${(3000 <= joinGroup) ? styles.checked : ''}`} src={`${imgRewardFbChecked}`} alt={'img-reward-fb-9-checked'} loading="lazy"/>
        <img className={`${styles.imgRewardFb} ${styles.rewardFb10} ${styles.none} ${(4000 <= joinGroup) ? styles.checked : ''}`} src={`${imgRewardFbChecked}`} alt={'img-reward-fb-10-checked'} loading="lazy"/>
        <img className={styles.btnFollowFb} src={`${btnFollowFb}`} alt={'btn-follow-fb'} onClick={() => handleClickFollowButton(1)} />
        <img className={styles.btnJoinGroup} src={`${btnJoinGroup}`} alt={'btn-join-group'} onClick={() => { handleClickFollowButton(2) }} />
      </div>
    </>
  )
}

export default Section2;
