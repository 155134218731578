import { useState, useRef, useEffect } from 'react';
import apiHelper from '../../helper/apiHelper';
import CDNBackground from '../../components/CDNBackground';
import handleGAevent from '../../helper/analytic';
import ReactPixel from 'react-facebook-pixel';
import ImageButton from '../../components/Button';
import PcSection1 from './PcSection1';
import PcSection2 from './PcSection2';
import PcSection3 from './PcSection3';
import PcSection4 from './PcSection4';
import FooterSection from './FooterSection';
import PreRegisPopUp from './PreRegisPopUp';
import OverlayMenu from '../../components/Overlaymenu';
import IconVideoPlayer from '../../components/IconVideoPlayer';
import PopUpFullTeam from '../../components/PopUpFullTeam';
import styles from './DesktopPreRegister.module.scss';

import "react-step-progress-bar/styles.css";

const DesktopPreRegister = ({ cdn }) => {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const mainmenuRef = useRef(null);
  const containerRef = useRef(null);
  const pageTopContainerRef = useRef(null);
  const childRef = useRef(null)
  const innerScrollContentRef = useRef(null);
  const footerRef = useRef(null);
  const headerRef = useRef(null);
  const overlayRef = useRef(null);
  const [activeTab, setActiveTab] = useState('main-menu');
  const [contentHeight, setContentHeight] = useState(0);
  const [rewardAccpeted, setRewardAccepted] = useState(0);
  const [isTeamCreator, setIsTeamCreator] = useState(0);
  const [isMenuShow, setIsMenuShow] = useState(true);
  const [inviteUrl, setInviteUrl] = useState(true);
  const [email, setEmail] = useState('');

  // -- Pre Regis page state
  const [isPreRegisPageShow, setIsPreRegisPageShow] = useState(false);
  const [preRegisCount, setPreRegisCount] = useState(0);

  // -- Team Full state
  const [isTeamFullPageShow, setIsTeamFullPageShow] = useState(false);

  const tvUrl = `https://sko-cdn.zoltangames.com/web/btn-play-main-page.png`;
  const sloganUrl = `https://sko-cdn.zoltangames.com/preregister/img-slogan.png`
  const popUpBg = `https://sko-cdn.zoltangames.com/web/bg-pop-up-team-full.png`;
  const bgPage2 = {
    backgroundImage: `url(https://sko-cdn.zoltangames.com/preregister/bg-desktop-2.png)`,
  };
  const pageTopContainer = {
    backgroundImage: `url(https://sko-cdn.zoltangames.com/preregister/bg-top-layer.png)`
  };
  const header = {
    backgroundImage: `url(https://sko-cdn.zoltangames.com/preregister/bg-header-tab-page-1.png)`,
  };
  const tvStyle = {
    width: '15%',
    height: '15%',
    position: 'absolute',
    bottom: '10%',
    right: '4%',
    zIndex: '12'
  };
  const badgeButtonStyle = {
    height: '50%',
  };
  const badgeButtonDisplayNoneStyle = {
    height: '50%',
    display: 'none',
  };
  const preRegisStyle = {
    marginTop: '1%',
    height: '25%',
  };
  const arrowDownStyle = {
    marginTop: '1%',
    height: '5%',
  };
  const sloganStyle = {
    height: '100%',
  };
  const sloganDisplayNoneStyle = {
    height: '100%',
    display: 'none'
  };
  //-- Reward Accepted manage
  const fetchDataReward = async () => {
    try {
      const body = { email: email };
      const result = await apiHelper('users/count-reward', 'POST', body);

      const responseData = result.data.count;

      setIsTeamCreator(result.data.isCreator);
      setRewardAccepted(responseData);
    } catch (error) {

    }
  };

  useEffect(() => {
    if (email && email.trim() !== '') {
      fetchDataReward();
    }
  }, [email]);

  const handleRegisMenuShowOpen = () => {
    setIsPreRegisPageShow(true);
    handleGAevent('Page-1', 'Click_Pre-register', `Click Pre-register`);
    ReactPixel.trackCustom('OpenPreRegisPage');
  }
  useEffect(() => {
    const currentUrl = window.location.href;
    const queryString = currentUrl.split('?')[1];
    const queryParams = new URLSearchParams(queryString);
    const invite = queryParams.get('invite');
    console.log(invite);
    if (invite) {
      const fetchTeamData = async () => {
        try {
          const result = await apiHelper('users/check-team-status', 'GET',null,{ team: invite});
          if(result.responseCode === 1 || result.responseCode === 4){
            setIsTeamFullPageShow(true);
          }
        } catch (error) {
          console.log("fetch team data failed")
        }
      };
      fetchTeamData();
    }
  }, []);
  // -- Scroll management

  const scrollToTop = () => {
    innerScrollContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    mainmenuRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    const container = containerRef.current;
    container.style.overflowY = 'scroll';
    setActiveTab('main-menu');
  };

  useEffect(() => {
    if (activeTab !== 'main-menu' && activeTab !== 'section6') {
      setIsMenuShow(true);
    } else {
      setIsMenuShow(false)
    }

  }, [activeTab]);
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  useEffect(() => {
    const container = containerRef.current;
    const innerScroll = innerScrollContentRef.current;

    const handleContainerScroll = debounce(() => {
      const containerScrollTop = Math.ceil(container.scrollTop);
      const containerHeight = container.clientHeight;
      const containerScrollHeight = container.scrollHeight;
      if (containerScrollTop + containerHeight >= containerScrollHeight) {
        // Container is at the bottom, enable inner scroll
        innerScroll.style.overflowY = 'scroll'; // Enable inner scroll
        setIsMenuShow(true);
      } else {
        // Container is not at the bottom, disable inner scroll
        innerScroll.style.overflowY = 'hidden';
        if (containerScrollTop <= containerScrollHeight / 4) {
          setIsMenuShow(false);
        }
      }
    }, 100);
    const handleInnerScroll = debounce(() => {
      if (innerScroll.scrollTop > 0) {
        // Inner content is moving, lock container scroll
        container.style.overflowY = 'hidden';
      }

      if (innerScroll.scrollTop <= 10) {
        innerScroll.classList.remove('scrollable');
        container.style.overflowY = 'scroll';
      }
    }, 100);

    container.addEventListener('scroll', handleContainerScroll);
    innerScroll.addEventListener('scroll', handleInnerScroll);

    return () => {
      container.removeEventListener('scroll', handleContainerScroll);
      innerScroll.removeEventListener('scroll', handleInnerScroll);
    };
  }, []);

  useEffect(() => {
    if (isPreRegisPageShow) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isPreRegisPageShow]);
  useEffect(() => {
    if (isTeamFullPageShow) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isTeamFullPageShow]);

  useEffect(() => {
    const container = innerScrollContentRef.current;
    // const logo = logoRef.current;
    const header = headerRef.current;
    const overlay = overlayRef.current;
    const handleScroll = () => {
      const topContainer = pageTopContainerRef.current;
      const footer = footerRef.current;

      const footerOffsetTop = footer.offsetTop;
      const scrollY = container.scrollTop;
      const contentHeight = container.clientHeight;

      if (scrollY + contentHeight >= footerOffsetTop) {
        topContainer.style.transform = `translateY(-${scrollY + contentHeight - footerOffsetTop}px)`;
        // logo.style.transform = `translateY(-${scrollY + contentHeight - footerOffsetTop}px)`;
        header.style.transform = `translateY(-${scrollY + contentHeight - footerOffsetTop}px)`;
        overlay.style.transform = `translateY(-${scrollY + contentHeight - footerOffsetTop}px)`;
      } else {
        topContainer.style.transform = 'translateY(0)';
        // logo.style.transform = 'translateY(0)';
        header.style.transform = 'translateY(0)';
        overlay.style.transform = 'translateY(0)';
      }
    };

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);


  // -- Email cache
  useEffect(() => {
    try {
      const storedEmail = localStorage.getItem('email');
      if (storedEmail) {
        setEmail(storedEmail);
      }
    } catch (error) {
      console.error('Error accessing localStorage:', error);
    }
  }, []);
  useEffect(() => {
    try {
      localStorage.setItem('email', email);
    } catch (error) {
      console.error('Error accessing localStorage:', error);
    }
  }, [email]);

  const handleLinkClick = (ref, tabId) => {
    if (tabId === 'main-menu') {
      console.log('trigger');
      innerScrollContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
    scrollToRef(ref);
    setActiveTab(tabId);
    handleGAevent('HeaderTab', `Navigate_to_Section_${tabId}`, `Navigate to Section ${tabId}`);
    ReactPixel.trackCustom('HeaderTabClick', {
      tabId: tabId,
    });
  };
  const handleAppStore = () => {
    // handleGAevent('Page-1', 'Click_Appstore_Badge', `Click Appstore Badge`);
    // ReactPixel.trackCustom('ClickAppstoreBadge');
    // const appStorePageLink = 'https://apps.apple.com/app/samkok-origin/id6469506005';
    // window.open(appStorePageLink, '_blank');
  };

  const handleGooglePlayStore = () => {
    handleGAevent('Page-1', 'Click_Playstore_Badge', `Click Google Playstore Badge`);
    ReactPixel.trackCustom('ClickPlaystoreBadge');
    const playStorePageLink = 'https://play.google.com/store/apps/details?id=com.ztg.skorigin.aos';
    window.open(playStorePageLink, '_blank');
  }

  // -- Resizing handler
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContentHeight(Math.ceil(containerRef.current.offsetWidth * (9 / 19.5)));
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // -- Data sync fetch
  useEffect(() => {

    const fetchData = async () => {
      try {
        const result = await apiHelper('users/count-user', 'GET');
        const responseData = result.data.count;
        setPreRegisCount(() => (responseData));
      } catch (error) {
        setPreRegisCount((prevValue) => (prevValue));
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
  }, [section1Ref, section2Ref, section3Ref, section4Ref, mainmenuRef]);

  return (
    <div className={styles.container} ref={containerRef}>
      <PreRegisPopUp
        isPreRegisPageShow={isPreRegisPageShow}
        setIsPreRegisPageShow={setIsPreRegisPageShow}
        setRewardAccepted={setRewardAccepted}
        rewardAccepted={rewardAccpeted}
        isTeamCreator={isTeamCreator}
        setIsTeamCreator={setIsTeamCreator}
        fetchDataReward={fetchDataReward}
        email={email}
        setEmail={setEmail}
      ></PreRegisPopUp>
      <PopUpFullTeam cdn={cdn} isShow={isTeamFullPageShow}></PopUpFullTeam>
      <OverlayMenu
        showMenu={isMenuShow}
        handleScrollToTop={scrollToTop}
        overlayRef={overlayRef}></OverlayMenu>
      <div className={styles.pageTopContainer} style={pageTopContainer} ref={pageTopContainerRef}></div>
      <div className={styles.pageContainer} ref={mainmenuRef}>
        <div style={tvStyle}>
          <IconVideoPlayer
            cdn='https://sko-cdn.zoltangames.com/web/'
            videoSrc={'https://youtu.be/MBPUEOQnkbE?si=YABxeKkm1pcpk6fx'}
            thumbnailSrc={`${tvUrl}`}
            thumbnailWidth={'100%'}
            thumbnailHeight={'100%'}
            isPC={true}
          />
        </div>
        <CDNBackground cdnLink={`https://sko-cdn.zoltangames.com/preregister/vid-homepage-bg.webm`} poster={`https://sko-cdn.zoltangames.com/preregister/BG-PC-1.png`} isContain={false}></CDNBackground>
        <div className={styles.headerText}>
          วางอุบายร้อยพันสู่ชัยชนะหนึ่งเดียว
        </div>
        <div className={styles.txtCount}>{preRegisCount.toLocaleString()}</div>
        <div className={styles.txtHeader2}>จำนวนยอดลงทะเบียนล่วงหน้า</div>

        <div className={styles.menuContentContainer}>
          <div className={styles.badgeContainer}>
            <img src={`${sloganUrl}`} alt={'slogan'} style={sloganDisplayNoneStyle} />
            {/* <ImageButton onClick={handleAppStore} iconSrc="App_Store_Badge.png" altText="Icon" additionalButtonStyle={badgeButtonStyle} /> */}
            <ImageButton onClick={handleAppStore} iconSrc="btn-appstore-desktop-v2.png" altText="Icon" additionalButtonStyle={badgeButtonDisplayNoneStyle} />
            <ImageButton onClick={handleGooglePlayStore} iconSrc="google-play-badge.png" altText="Icon" additionalButtonStyle={badgeButtonDisplayNoneStyle} />
          </div>
          <ImageButton onClick={handleRegisMenuShowOpen} iconSrc="btn-pre-regis.png" altText="Icon" additionalButtonStyle={preRegisStyle} />
          <img src={`https://sko-cdn.zoltangames.com/preregister/img-arrow-down.png`} alt='icon' style={arrowDownStyle} />
        </div>
      </div>
      <div className={`${styles.pageContainer} ${styles.page2}`} ref={childRef} style={bgPage2}>
        <div className={styles.contentContainer} ref={innerScrollContentRef}>
          <PcSection1 sectionRef={section1Ref} contentHeight={contentHeight} preRegisCount={preRegisCount} setIsMenuShow={setIsMenuShow}></PcSection1>
          <PcSection2
            sectionRef={section2Ref}
            contentHeight={contentHeight}
            setIsPreRegisPageShow={setIsPreRegisPageShow}
            rewardAccepted={rewardAccpeted}
            isTeamCreator={isTeamCreator}
            setIsMenuShow={setIsMenuShow}>
          </PcSection2>
          <PcSection3 sectionRef={section3Ref} contentHeight={contentHeight} setIsMenuShow={setIsMenuShow} cdn={cdn}></PcSection3>
          <PcSection4 sectionRef={section4Ref} contentHeight={contentHeight} cdn={cdn}></PcSection4>
          <FooterSection sectionRef={footerRef} inviteUrl={inviteUrl} cdn={cdn}></FooterSection>
        </div>
      </div>

      <div className={styles.headerMenu} style={header} ref={headerRef}>
        <div className={styles.menuBar}>
          <a onClick={() => handleLinkClick(mainmenuRef, 'main-menu')} className={activeTab === 'main-menu' ? styles['activeTab'] : ''}>ลงทะเบียนล่วงหน้า</a>
          <a onClick={() => handleLinkClick(section1Ref, 'section1')} className={activeTab === 'section1' ? styles['activeTab'] : ''}>รางวัลลงทะเบียน</a>
          <a onClick={() => handleLinkClick(section2Ref, 'section2')} className={activeTab === 'section2' ? styles['activeTab'] : ''}>กิจกรรมล่าขุมทรัพย์</a>
          <a onClick={() => handleLinkClick(section3Ref, 'section3')} className={activeTab === 'section3' ? styles['activeTab'] : ''}>แนะนำตัวละคร</a>
          <a onClick={() => handleLinkClick(section4Ref, 'section4')} className={activeTab === 'section4' ? styles['activeTab'] : ''}>แนะนำระบบ</a>
        </div>
      </div>
    </div>
  );
}

export default DesktopPreRegister;
