import React from 'react';
import ReactLoading from 'react-loading';
import styles from './LoadingIndicator.module.scss';

const LoadingIndicator = ({color = '#79694D', height = '5rem', width = '5rem'}) => {
  return (
    <div className={styles.LoadingIndicator}>
      <ReactLoading type="spin" color={color} height={height} width={width} />
    </div>
  );
};

export default LoadingIndicator;