import React, { useEffect, useState, useRef } from 'react';
import './CDNBackground.scss';

function CDNBackground({ cdnLink, poster, isContain, hoverPlay = false }) {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [error, setError] = useState(null);
  const videoRef = useRef(null);

  const handleMouseEnter = () => {
    if (hoverPlay && videoRef.current) {
      videoRef.current.play()
        .then(() => {
          setVideoPlaying(true);
        })
        .catch((err) => {
          setError('Error playing video on hover.');
          console.error('Error playing video on hover:', err);
        });
    }
  };

  const handleMouseLeave = () => {
    if (hoverPlay && videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0; // Reset video to the start if needed
      setVideoPlaying(false);
    }
  };

  useEffect(() => {
    setVideoPlaying(!hoverPlay);
  }, [cdnLink]);

  useEffect(() => {
    const handleEnded = () => {
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('ended', handleEnded);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleEnded);
      }
    };
  }, []);

  useEffect(() => {
    if (videoRef.current && !hoverPlay) {
      const playVideo = () => {
        videoRef.current.play()
          .then(() => {
            setVideoPlaying(true);
          })
          .catch((err) => {
            if (err.name === 'NotAllowedError' || err.name === 'AbortError') {
              console.log('Auto-play prevented:', err);
              document.addEventListener('visibilitychange', handleVisibilityChange);
            } else {
              setError('Error auto-playing video.');
              console.error('Error auto-playing video:', err);
            }
          });
      };

      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          playVideo();
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        }
      };
      playVideo();
    }
  }, [hoverPlay]);

  return (
    <div className={`cdn-video-background ${videoPlaying ? 'video-playing' : ''}`} 
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}>
      {poster && <img src={poster} alt="Video Poster" className="video-poster" />}
      <video
        ref={videoRef}
        className="cdn-background"
        style={{ objectFit: isContain ? 'contain' : 'cover' }}
        src={cdnLink}
        controls={false}
        autoPlay={!hoverPlay}
        muted
        loop
        playsInline
        preload="auto"
        onError={(e) => {
          setError('Error loading video.');
          console.error('Error loading video:', e);
        }}
      ></video>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default CDNBackground;
