
import { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

import LoadingIndicator from '../../components/LoadingIndicator';
import handleGAevent from '../..//helper/analytic';
import apiHelper from '../../helper/apiHelper';
import { FACEBOOK_APP_ID } from '../../consts/facebookData';
import { FacebookMessengerShareButton, FacebookShareButton, LineShareButton, TwitterShareButton } from 'react-share';

import PopUpPreRegisterReward from './PopUpPreRegisterReward';

import styles from './PopUpPreRegister.module.scss';


const PopUpPreRegister = ({ cdn, email, setEmail, fetchDataReward, rewardAccepted, setRewardAccepted, isTeamCreator, setIsTeamCreator, handleRegisMenuShowClose }) => {
  const btnNext = cdn + 'btn-next.png';
  const btnPrevious = cdn + 'btn-previous.png';
  const btnCreateTeam = cdn + 'btn-create-team.png';
  const btnJoinTeam = cdn + 'btn-join-team.png';
  const btnCopy = cdn + 'btn-copy.png';
  const btnPaste = cdn + 'btn-paste.png';
  const btnShare = cdn + 'btn-share-pre-register.png';
  const btnClose = cdn + 'btn-close-pre-register-menu.png';
  const btnLine = cdn + 'btn-share-line.png';
  const btnX = cdn + 'btn-share-x.png';
  const btnFacebook = cdn + 'btn-share-facebook.png';
  const btnMessenger = cdn + 'btn-share-messenger.png';
  const imgHeaderText = cdn + 'txt-header.png';
  const imgCharacter1 = cdn + 'char1.png';
  const imgCharacter2 = cdn + 'char2.png';
  const imgCharacter3 = cdn + 'char3.png';
  const imgCharacter4 = cdn + 'char4.png';
  const infoText = cdn + `img-info-teamfull-v2.png`;
  const character = cdn + `img-character-thanks.png`;

  const [preRegisSelectedCharacter, setPreRegisSelectedCharacter] = useState(1);
  const imgCharacterSelected = cdn + `char${preRegisSelectedCharacter}.png`;

  const [preRegisCurrentPage, setpreRegisCurrentPage] = useState(1);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [teamId, setTeamId] = useState(0);
  const [inputedTeamId, setInputedTeamId] = useState(0);
  const [displayText, setDisplayText] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inviteUrlRef = useRef(null);
  const inputedTeamIdRef = useRef(null);
  const [inviteUrl, setInviteUrl] = useState(window.location.origin);
  const teamCodeRef = useRef(null);
  const titleToShare = 'ทัพของเราต้องการคุณ เข้าร่วมเพื่อรับรางวัล x10';
  const hashtag = ['#samkokorigin'];


  const characterData = [
    {
      id: 0, name: 'Cáo Cāo',
    },
    {
      id: 1, name: 'Guan yu',
    },
    {
      id: 2, name: 'Zhuge Liang',
    },
    {
      id: 3, name: 'lubu',
    },
  ];

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  }


  const handleValidateEmail = (event) => {
    // handleGAevent('Page-PreRegis-1', 'Click_Submit_Email', 'Click Submit Email');
    // ReactPixel.track('SubmitApplication', {
    //   action: 'Click_Submit_Email',
    //   email: email
    // })

    //Fe test
    event.preventDefault();
    setIsLoading(true);
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValidEmail = emailRegex.test(email);

    //Be test and register
    const testEmail = async () => {
      try {
        const body = { email: email };
        const result = await apiHelper('users/register-user', 'POST', body);
        switch (result.responseCode) {
          case 1:
            //User exist case
            handleGAevent('Page-PreRegis-1', 'Click_Login_Email', `Click Login`);
            ReactPixel.trackCustom('ClickLoginEmail', {
              action: 'Click_Login_Email',
              email: email
            });
            let userCreate = result.data;
            if (userCreate.vote === null) {
              setIsLoading(false);
              setIsEmailValid(true);
              fetchDataReward();
              setpreRegisCurrentPage((prevCount) => prevCount + 1);
            } else if (userCreate.team === null) {
              if (inputedTeamId !== null && inputedTeamId !== undefined && inputedTeamId !== 0) {
                setIsLoading(false);
                setIsEmailValid(true);
                fetchDataReward();
                if (teamId !== null && teamId !== undefined && teamId !== 0) {
                  setInputedTeamId(teamId);
                }
                setpreRegisCurrentPage(5);
              } else {
                setIsLoading(false);
                setIsEmailValid(true);
                fetchDataReward();
                setpreRegisCurrentPage(4);
              }
            } else {
              setIsLoading(false);
              setIsEmailValid(true);
              fetchDataReward();
              setTeamId(userCreate.team);
              // updateInviteUrl(userCreate.team);
              setpreRegisCurrentPage(7);
            }
            break;
          case 2:
            //User not exist and success regis
            handleGAevent('Page-PreRegis-1', 'Click_Register_Email', `Click Register`);
            ReactPixel.trackCustom('ClickRegisterEmail', {
              action: 'Click_Register_Email',
              email: email
            });
            setIsLoading(false);
            setIsEmailValid(isValidEmail);
            setpreRegisCurrentPage((prevCount) => prevCount + 1);
            console.log('Registration success');
            break;
          case 0:
            console.log('Registration failed');
            setIsLoading(false);
            setIsEmailValid(false);
            break;
          default:
            break;
        }
      } catch (error) {
        setIsLoading(false);
        setIsEmailValid(false);
        console.error('Error:', error);
      }
    };
    if (isValidEmail) {
      testEmail();
    } else {
      setIsLoading(false);
      setIsEmailValid(false);
    }
  }

  const handleRadioChange = (event) => {
    handleGAevent('Page-PreRegis-2', 'Select_Character', 'Select Character');
    ReactPixel.trackCustom('SelectCharacterPreRegisPage', {
      userVote: preRegisSelectedCharacter,
      characterSelected: characterData[preRegisSelectedCharacter - 1].name
    });
    const selectedValue = event.target.value;
    setPreRegisSelectedCharacter(parseInt(selectedValue, 10));
    setDisplayText(false);
  }

  const handleSelectCharacter = () => {
    // handleGAevent('Page-PreRegis-2', 'Click_Vote_Character', 'Click Vote Character');
    // ReactPixel.track('SubmitApplication', {
    //   action: 'Click Vote Character',
    //   email: email,
    //   vote: preRegisSelectedCharacter,
    //   voteCharacter: characterData[preRegisSelectedCharacter - 1].name
    // });
    handleGAevent('Page-PreRegis-2', 'Click_Vote_Character', `Click Vote Character`);
    ReactPixel.trackCustom('ClickVoteCharacter', {
      action: 'Click_Vote_Character',
      email: email,
      vote: preRegisSelectedCharacter,
      voteCharacter: characterData[preRegisSelectedCharacter - 1].name
    });
    if (preRegisSelectedCharacter !== null) {
      const voteCharacter = async () => {
        try {
          const body = { email: email, vote: preRegisSelectedCharacter };
          const result = await apiHelper('users/vote-character', 'POST', body);

          switch (result.responseCode) {
            case 1:
              console.log('Vote Failed');
              setDisplayText(true);
              break;
            case 0:
              console.log('Vote Success');
              setDisplayText(false);
              handleNextPage();
              break;
            default:
              break;
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };
      voteCharacter();
      handleCharacterShareLink();
    } else {
      setDisplayText(true);
    }
  }

  const handleNextPage = (eventName = '') => {
    handleGAevent('Button', eventName, eventName);
    const sanitizedEventName = eventName.includes('_') ? eventName.replace(/_/g, ' ') : eventName;
    ReactPixel.trackCustom(sanitizedEventName);
    setpreRegisCurrentPage((prevCount) => prevCount + 1);
  }

  const handlePreviousAmountPage = (amount, eventName = 'Back_to_Previous_Page') => {
    setpreRegisCurrentPage((prevCount) => prevCount - amount);
    handleGAevent('Button', eventName, eventName);
    const sanitizedEventName = eventName.includes('_') ? eventName.replace(/_/g, ' ') : eventName;
    ReactPixel.trackCustom(sanitizedEventName);
  };

  const handleCopyText = async (valueToCopy) => {
    handleGAevent('Button', 'Click_Copy_Text', `Handle Copy Text`);
    ReactPixel.trackCustom('ClickCopyText');
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(valueToCopy);
      } else {
        // Fallback for older browsers
        const textarea = document.createElement('textarea');
        textarea.value = valueToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }
    } catch (error) {
      console.error('Error copying text to clipboard:', error);
    }
  };

  const handlePasteText = async (ref) => {
    handleGAevent('Button', 'Click_Paste_Text', 'Click Paste Text Button');
    ReactPixel.trackCustom('ClickPasteText');
    try {
      let text;
      if (navigator.clipboard && navigator.clipboard.readText) {
        text = await navigator.clipboard.readText();
      } else {
        // Clipboard read is not supported by fallback
        throw new Error('Clipboard read not supported');
      }
      if (ref.current) {
        ref.current.value = text; // Update input value
        if (/^\d+$/.test(text)) {
          setInputedTeamId(text); // Update state
        }
      }
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };

  const handleCharacterShareLink = () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.search = '';
    url.searchParams.set('id', preRegisSelectedCharacter);
    const updatedUrl = url.href;
    setInviteUrl(updatedUrl);
  };

  function shareUrl(text) {
    handleGAevent('Button', 'Share_Button_Click', 'Share to Social Media');
    ReactPixel.trackCustom('ShareButtonClick', {
      sharedLink: inviteUrl,
    });

    if (navigator.share) {
      navigator.share({
        title: text,
        text: text,
        url: inviteUrl,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      console.log('Web Share API is not supported in this browser.');
      setpreRegisCurrentPage(8);
    }
  }

  const handleLineShare = () => {
    const lineUrl = `https://line.me/R/msg/text/?${encodeURIComponent(titleToShare)}%20${encodeURIComponent(inviteUrl)}`;
    window.open(lineUrl, '_blank');
  };


  const handleCreateTeam = () => {
    // handleGAevent('Page-PreRegis-4', 'Click_Create_Team', 'Click Create Team');
    // ReactPixel.track('SubmitApplication', {
    //   action: 'Click Create Team',
    //   email: email
    // });
    const createTeam = async () => {
      try {
        const body = { email: email };
        const result = await apiHelper('users/create-team', 'POST', body);

        switch (result.responseCode) {
          case 1:
            console.log('Email does not exists');
            break;
          case 2:
            console.log('Email does not vote character yet');
            break;
          case 3:
            console.log('User already has team');
            setTeamId(result.data.team);
            setRewardAccepted(1);
            setInviteUrl(`${window.location.href}?invite=${result.data.team}`);
            break;
          case 0:
            console.log('Create Success');
            setTeamId(result.data.team);
            setRewardAccepted(1);
            setIsTeamCreator(true);
            setInviteUrl(`${window.location.href}?invite=${result.data.team}`);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    createTeam();
    fetchDataReward();
    handleGAevent('Page-PreRegis-4', 'Click_Create_Team', `Click Create Team`);
    ReactPixel.trackCustom('ClickCreateTeam', {
      action: 'Click_Create_Team',
      email: email
    });
    handleNextPage();
  }

  const handleNextAmountPage = (amount, eventName = '') => {
    handleGAevent('Button', eventName, eventName);
    const sanitizedEventName = eventName.includes('_') ? eventName.replace(/_/g, ' ') : eventName;
    ReactPixel.trackCustom(sanitizedEventName);
    setpreRegisCurrentPage((prevCount) => prevCount + amount);
  }

  useEffect(() => {
    const currentUrl = window.location.href;
    const queryString = currentUrl.split('?')[1];
    const queryParams = new URLSearchParams(queryString);
    const invite = queryParams.get('invite');
    if (invite) {
      setInputedTeamId(invite);
    }
  }, []);

  // --  combine url to team id
  useEffect(() => {
    if (teamId) {
      const currentUrl = new URL(window.location.href);
      currentUrl.search = '';
      currentUrl.searchParams.set('invite', teamId);
      setInviteUrl(currentUrl);
    }
  }, [teamId]);

  const handleInputChange = (event) => {
    setInputedTeamId(event.target.value);
  }

  const handleJoinTeam = () => {
    // handleGAevent('Page-PreRegis-4', 'Handle_Join_Team', `Handle Join Team`);
    // ReactPixel.track('SubmitApplication', {
    //   action: 'Handle Join Team',
    //   email: email,
    //   team: inputedTeamId
    // });
    const JoinTeam = async () => {
      try {
        const body = { email: email, team: inputedTeamId };
        const result = await apiHelper('users/join-team', 'POST', body);

        switch (result.responseCode) {
          case 1:
            setpreRegisCurrentPage(9);
            console.log('Team does not exist');
            break;
          case 2:
            setpreRegisCurrentPage(9);
            console.log('User with this email does not exists');
            break;
          case 3:
            setpreRegisCurrentPage(9);
            console.log('User already has team');
            break;
          case 4:
            setpreRegisCurrentPage(9);
            console.log('Team is full');
            break;
          case 0:
            console.log('Join Sucess');
            setRewardAccepted(result.data.teamMember);
            handleNextPage();
            fetchDataReward();
            break;
          default:
            break;
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    JoinTeam();
    handleGAevent('Page-PreRegis-4', 'Handle_Join_Team', `Handle Join Team`);
    ReactPixel.trackCustom('HandleJoinTeam', {
        action: 'Handle_Join_Team',
        email: email,
        team: inputedTeamId
    });
  }

  return (
    <>
      {preRegisCurrentPage === 1 ? (
        <div className={styles.page1}>
          {isLoading && <LoadingIndicator />}
          <form>
            <div>
              <div className={styles.inputLabel}>E-mail</div>
              <input
                className={styles.inputForm}
                name="Email"
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="example@email.com"
              />
            </div>
            {isEmailValid ? (
              <div className={styles.txtPreRegis}>
                เมื่อคลิกลงทะเบียน แสดงว่าคุณเข้าใจและตกลงตาม<br />
                <Link reloadDocument to="/term-of-service" className={styles.footerLink}>
                  ข้อกำหนดและเงื่อนไขการใช้บริการ
                </Link>
                <span> และ </span>
                <Link reloadDocument to="/privacy-policy" className={styles.footerLink}>
                  นโยบายความเป็นส่วนตัว
                </Link>
              </div>
            ) : (
              <div className={styles.txtPreRegis} style={{ color: 'red', justifyContent: 'flex-start' }}>
                *กรุณาตรวจสอบ email
              </div>
            )}
            <div className={styles.btnNextContainer}>
              <img key="btn-next-page1" className={styles.btnNext} src={`${btnNext}`} alt="btn-next" onClick={handleValidateEmail} />
            </div>
          </form>
        </div>
      ) : preRegisCurrentPage === 2 ? (
        <div className={styles.page2}>
          <img className={styles.headerText} key="header-text" src={imgHeaderText} alt="header" />
          {displayText ? (
            <div className={styles.txtPreRegis} style={{ color: 'red', justifyContent: 'center' }}>
              *กรุณาเลือกขุนศึก
            </div>
          ) : null}
          <form className={styles.characterVoteContainer} action="">
            {[
              { imgSrc: imgCharacter1, altText: 'character1' },
              { imgSrc: imgCharacter2, altText: 'character2' },
              { imgSrc: imgCharacter3, altText: 'character3' },
              { imgSrc: imgCharacter4, altText: 'character4' }
            ].map((character, index) => (
              <label key={`character-label-${index}`} className={styles.characterPlaceholder}>
                <input type="radio" name="radio" value={index + 1} onChange={handleRadioChange} />
                <span className={styles.checkmark}></span>
                <div className={styles.imageContainer}>
                  <img className={styles.characterSRegister} key={`character-img-${index}`} src={character.imgSrc} alt={character.altText} />
                  <div className={styles.characterSRegisOverlay} />
                </div>
              </label>
            ))}
          </form>
          <div className={styles.btnNextContainer}>
            <img key="btn-next-page2" className={styles.btnNext} src={`${btnNext}`} alt="btn-next" onClick={handleSelectCharacter} />
          </div>
        </div>
      ) : preRegisCurrentPage === 3 ? (
        <div className={styles.page3}>
          <div className={styles.bigHeaderPreRegisText}>
            ขอบคุณสำหรับการลงทะเบียนล่วงหน้า
          </div>
          <div className={styles.smallHeaderPreRegisText}>
            ท่านเลือกโหวตละคร
          </div>
          <img className={styles.characterSelectedRegister} key="character-selected" src={imgCharacterSelected} alt="character-selected" />
          <div className={styles.disabledInputContainer}>
            <input ref={inviteUrlRef} className={`${styles.inputForm} ${styles.disableInputStyle2}`} value={inviteUrl} disabled />
            <img className={styles.btnCopy} src={`${btnCopy}`} alt="btn-copy" onClick={() => handleCopyText(inviteUrl)} />
          </div>
          <div className={styles.btnNextContainer}>
            <img key="btn-next-page3" className={styles.btnNext} src={`${btnNext}`} alt="btn-next" onClick={() => handleNextPage('Click Finish Voting')} />
          </div>
        </div>
      ) : preRegisCurrentPage === 4 ? (
        <div className={styles.page4}>
          <div className={styles.bigHeaderPreRegisText}>
            กิจกรรม จัดทีมล่าขุมทรัพย์ สมบัติคลังหลวง
          </div>
          <div className={styles.btnContainer}>
            <img className={styles.btnCreateTeam} key="btn-create-team" src={`${btnCreateTeam}`} alt="btn-create-team" onClick={handleCreateTeam} />
            <img className={styles.btnJoinTeam} key="btn-join-team" src={`${btnJoinTeam}`} alt="btn-join-team" onClick={() => handleNextAmountPage(2, 'Click Fill Team Code')} />
          </div>
        </div>
      ) : preRegisCurrentPage === 5 ? (
        <div className={styles.page5}>
          <div className={styles.bigHeaderPreRegisText}>
            รหัสชวนเพื่อนของท่านคือ
          </div>
          <div className={styles.disabledInputContainer}>
            <input className={`${styles.inputForm} ${styles.disableInputStyle}`} value={teamId} disabled />
            <img className={styles.btnCopy} src={`${btnCopy}`} alt="btn-copy" onClick={() => handleCopyText(teamId)} />
          </div>
          <div className={styles.disabledInputContainer}>
            <input ref={teamCodeRef} className={`${styles.inputForm} ${styles.disableInputStyle2}`} value={inviteUrl} disabled />
            <img className={styles.btnCopy} src={`${btnCopy}`} alt="btn-copy" onClick={() => handleCopyText(inviteUrl)} />
          </div>
          <div className={styles.btnNextContainer}>
            <img key="btn-next-page5" className={styles.btnNext} src={`${btnNext}`} alt="btn-next" onClick={() => handleNextAmountPage(2, 'Click next Button')} />
          </div>
        </div>
      ) : preRegisCurrentPage === 6 ? (
        <div className={styles.page6}>
          <div className={styles.bigHeaderPreRegisText}>
            กรอกรหัสชวนเพื่อน
          </div>
          <div className={styles.disabledInputContainer}>
            <input ref={inputedTeamIdRef} className={`${styles.inputForm} ${styles.disableInputStyle}`} onChange={handleInputChange} value={inputedTeamId} />
            {/* {!isSafari() && <img className={styles.btnPaste} src={`${btnPaste}`} alt={'btn-paste'} onClick={() => handlePasteText(inputedTeamIdRef)} />} */}
          </div>
          <div className={styles.btnNextContainer}>
            <img key="btn-previous-page6" className={styles.btnNext} src={`${btnPrevious}`} alt="btn-previous" onClick={() => handlePreviousAmountPage(3)} />
            <img key="btn-next-page6" className={styles.btnNext} src={`${btnNext}`} alt="btn-next" onClick={handleJoinTeam} />
          </div>
        </div>
      ) : preRegisCurrentPage === 7 ? (
        <div className={styles.page7}>
          <div className={styles.bigHeaderPreRegisText}>
            ชวนเพื่อนสำเร็จแล้ว {isTeamCreator ? rewardAccepted - 1 : rewardAccepted} คน
          </div>
          <div className={styles.teamRewardContainer}>
            <PopUpPreRegisterReward cdn={cdn} rewardAccepted={rewardAccepted} />
          </div>
          <div className={styles.btnRewardContainer}>
            <img className={styles.btnShare} key="btn-share" src={`${btnShare}`} alt="btn-share" onClick={() => shareUrl('ทัพของเราต้องการคุณ เข้าร่วมเพื่อรับรางวัล x10')} />
            <img className={styles.btnShare} key="btn-close" src={`${btnClose}`} alt="btn-close" onClick={handleRegisMenuShowClose} />
          </div>
        </div>
      ) : preRegisCurrentPage === 8 ? (
        <div className={styles.page8}>
          <div className={styles.bigHeaderPreRegisText}>
            Share
          </div>
          <div className={styles.shareContainer}>
            <FacebookShareButton url={inviteUrl} className={styles.shareBtn} quote={titleToShare} >
              <div className={styles.itemWrapper}>
                <img className={styles.btnShareIcon} key="btn-facebook" src={`${btnFacebook}`} alt="btn-facebook" />
                <div className={styles.itemLabel}>
                  Facebook
                </div>
              </div>
            </FacebookShareButton>
            <FacebookMessengerShareButton appId={FACEBOOK_APP_ID} url={inviteUrl} className={styles.shareBtn}>
              <div className={styles.itemWrapper}>
                <img className={styles.btnShareIcon} key="btn-messenger" src={`${btnMessenger}`} alt="btn-messenger" />
                <div className={styles.itemLabel}>
                  Messenger
                </div>
              </div>
            </FacebookMessengerShareButton>
            <TwitterShareButton url={inviteUrl} title={titleToShare} hashtags={hashtag} className={styles.shareBtn} >
              <div className={styles.itemWrapper}>
                <img className={styles.btnShareIcon} src={`${btnX}`} alt={'btn-x'} />
                <div className={styles.itemLabel}>
                  X
                </div>
              </div>
            </TwitterShareButton>
            <button onClick={handleLineShare} className={styles.shareBtn}>
              <div className={styles.itemWrapper}>
                <img className={styles.btnShareIcon} src={`${btnLine}`} alt={'btn-line'} />
                <div className={styles.itemLabel}>
                  Line
                </div>
              </div>
            </button>
          </div>
        </div>
      ) : preRegisCurrentPage === 9 ? (<div className={styles.page9}>
        <img className={styles.character} src={character} alt={'maskot'} />
        <img className={styles.infoText} src={infoText} alt={'info-text'} />
        <div className={`${styles.btnNextContainer}`}>
          <img key="btn-next-page9" className={styles.btnNext} onClick={() => { handlePreviousAmountPage(3) }} src={btnPrevious} alt="btn-previous" />
        </div>
        <div style={{ height: '10%' }}></div>
      </div>
      ) : ''}
    </>
  );
}

export default PopUpPreRegister;
