import styles from './PreRegisPopUp.module.scss';
import handleGAevent from '../../helper/analytic';
import ReactPixel from 'react-facebook-pixel';
import PopUp from '../../components/PopUp';
import ImageButton from '../../components/Button';
import { useState, useEffect, useRef } from 'react';
import apiHelper from '../../helper/apiHelper';
import { CHARACTER_DATA } from '../../consts/characterData';
import { Helmet } from 'react-helmet';
import { FacebookMessengerShareButton, FacebookShareButton, LineShareButton, TwitterShareButton } from 'react-share';
import LoadingIndicator from '../../components/LoadingIndicator';
import { FACEBOOK_APP_ID } from '../../consts/facebookData';

const PreRegisPopUp = ({
    isPreRegisPageShow,
    setIsPreRegisPageShow,
    rewardAccepted,
    setRewardAccepted,
    isTeamCreator,
    setIsTeamCreator,
    fetchDataReward,
    email,
    setEmail }) => {

    const [teamId, setTeamId] = useState(0);
    const [inputedTeamId, setInputedTeamId] = useState(0);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [preRegisCurrentPage, setpreRegisCurrentPage] = useState(1);
    const [displayText, setDisplayText] = useState(false);
    const [preRegisSelectedCharacter, setPreRegisSelectedCharacter] = useState(3);
    const [inviteUrl, setInviteUrl] = useState(window.location.origin);
    const [characterInviteUrl, setCharacterInviteUrl] = useState(window.location.origin);
    const [isLoading, setIsLoading] = useState(false);
    const inviteUrlRef = useRef(null);
    const teamCodeRef = useRef(null);
    const inputedTeamIdRef = useRef(null);

    const popUpBg = `https://sko-cdn.zoltangames.com/preregister/bg-pop-up-mailbox.png`;
    const bannerOgUrl = `https://sko-cdn.zoltangames.com/preregister/OG-banner-2.jpg`;
    const headerTextSection2Url = `https://sko-cdn.zoltangames.com/preregister/img-headertext-preregis-2.png`;
    const character1Url = `https://sko-cdn.zoltangames.com/preregister/char1.png`;
    const character2Url = `https://sko-cdn.zoltangames.com/preregister/char2.png`;
    const character3Url = `https://sko-cdn.zoltangames.com/preregister/char3.png`;
    const character4Url = `https://sko-cdn.zoltangames.com/preregister/char4.png`;
    const characterSelectedUrl = `https://sko-cdn.zoltangames.com/preregister/char${preRegisSelectedCharacter}.png`;
    const createTeamIcon = `https://sko-cdn.zoltangames.com/preregister/img-create-team-icon.png`;
    const joinTeamIcon = `https://sko-cdn.zoltangames.com/preregister/img-join-team-icon.png`;
    const rewardBox1 = 'https://sko-cdn.zoltangames.com/preregister/reward_box_1.png';
    const rewardBox2 = 'https://sko-cdn.zoltangames.com/preregister/reward_box_1.png';
    const rewardAccept = 'https://sko-cdn.zoltangames.com/preregister/reward_accept.png';
    const rewardBg = 'https://sko-cdn.zoltangames.com/preregister/bg-reward-page-3.png';
    const createTeamBtn = `https://sko-cdn.zoltangames.com/preregister/btn-create-team.png`;
    const joinTeamBtn = `https://sko-cdn.zoltangames.com/preregister/btn-join-team.png`;
    const infoText = `https://sko-cdn.zoltangames.com/web/img-info-team-full.png`;
    const character = `https://sko-cdn.zoltangames.com/web/img-character-thanks.png`;

    const characterSharingText = '';
    const titleToShare = 'ทัพของเราต้องการคุณ เข้าร่วมเพื่อรับรางวัล x10';
    const hashtag = ['#samkokorigin'];

    const containerStylePreRegis = {
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        zIndex: 10
    }

    const isSafari = () => {
        const ua = navigator.userAgent;
        return /^((?!chrome|android).)*safari/i.test(ua);
    };

    const shareButtonStyle = {
        width: '100%',
    }
    const frameHeaderContainer = {
        backgroundImage: `url(https://sko-cdn.zoltangames.com/preregister/bg-text-page-3.png)`
    };

    const characterSRegisStyle = {
        width: '100%',
        marginTop: '15%'
    };

    const nextButtonStyle = {
        width: '10vw',
        display: 'flex',
        justifyContent: 'center',
    }

    const joinButtonStyle = {
        width: '10vw',
        position: 'absolute',
        justifyContent: 'center',
        bottom: '27%',
        right: '25%'
    }

    const createButtonStyle = {
        width: '10vw',
        position: 'absolute',
        justifyContent: 'center',
        bottom: '27%',
        left: '20%'
    }

    const formStyle = {
        width: '100%',
        display: 'flex',
        marginTop: '2%',
        justifyContent: 'space-around'
    }

    const copyButtonStyle = {
        height: '5rem',
        width: '5rem',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '3%'
    }
    const handleRegisMenuShowClose = () => {
        handleGAevent('Button', 'Close_PreRegis_Page', `Close PreRegis Page`);
        ReactPixel.trackCustom('ClosePreRegisPage');
        setIsPreRegisPageShow(false);
    }

    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
    };
    const handleRadioChange = (event) => {
        const selectedValue = event.target.value;
        setPreRegisSelectedCharacter(parseInt(selectedValue, 10));
        setDisplayText(false);
        console.log(CHARACTER_DATA[selectedValue - 1].name);
        handleGAevent('Page-PreRegis-2', 'Select_Character', `Select Character`);
        ReactPixel.trackCustom('SelectCharacterPreRegisPage', {
            userVote: preRegisSelectedCharacter,
            characterSelected: CHARACTER_DATA[selectedValue - 1].name
        });
    };

    const handlePreviousPage = () => {
        setpreRegisCurrentPage((prevCount) => prevCount - 1);
        handleGAevent('Button', 'Back_to_Previous_Page', `Back to Previous Page`);
        ReactPixel.trackCustom('BackToPreviousPage');
    };

    const handlePreviousAmountPage = (amount, eventName = 'Back_to_Previous_Page') => {
        setpreRegisCurrentPage((prevCount) => prevCount - amount);
        handleGAevent('Button', eventName, eventName);
        const sanitizedEventName = eventName.includes('_') ? eventName.replace(/_/g, ' ') : eventName;
        ReactPixel.trackCustom(sanitizedEventName);
    };

    const handleNextPage = (eventName = 'Go_To_Next_Page') => {
        setpreRegisCurrentPage((prevCount) => prevCount + 1);
        handleGAevent('Button', eventName, eventName);
        const sanitizedEventName = eventName.includes('_') ? eventName.replace(/_/g, ' ') : eventName;
        ReactPixel.trackCustom(sanitizedEventName);
    };

    const handleNextAmountPage = (amount, eventName = 'Go_To_Next_Page') => {
        setpreRegisCurrentPage((prevCount) => prevCount + amount);
        handleGAevent('Button', eventName, eventName);
        const sanitizedEventName = eventName.includes('_') ? eventName.replace(/_/g, ' ') : eventName;
        ReactPixel.trackCustom(sanitizedEventName);
    };

    const handleCopyText = async (valueToCopy) => {
        handleGAevent('Button', 'Click_Copy_Text', `Handle Copy Text`);
        ReactPixel.trackCustom('ClickCopyText');
        try {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                await navigator.clipboard.writeText(valueToCopy);
            } else {
                // Fallback for older browsers
                const textarea = document.createElement('textarea');
                textarea.value = valueToCopy;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
            }
        } catch (error) {
            console.error('Error copying text to clipboard:', error);
        }
    };

    const handlePasteText = async (ref) => {
        handleGAevent('Button', 'Click_Paste_Text', 'Click Paste Text Button');
        ReactPixel.trackCustom('ClickPasteText');
        try {
            let text;
            if (navigator.clipboard && navigator.clipboard.readText) {
                text = await navigator.clipboard.readText();
            } else {
                // Clipboard read is not supported by fallback
                throw new Error('Clipboard read not supported');
            }
            if (ref.current) {
                ref.current.value = text; // Update input value
                if (/^\d+$/.test(text)) {
                    setTeamId(text); // Update state
                }
            }
        } catch (err) {
            console.error('Failed to read clipboard contents: ', err);
        }
    };

    const handleInputChange = (event) => {
        const newTeamId = event.target.value;
        setInputedTeamId(newTeamId);
    };

    const handleValidateEmail = (event) => {
        event.preventDefault();
        setIsLoading(true);
        //tracking tools
        // handleGAevent('Page-PreRegis-1', 'Click_Submit_Email', `Click Submit Email`);
        // ReactPixel.track('SubmitApplication', {
        //     action: 'Click_Submit_Email',
        //     email: email
        // });

        //Fe test
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const isValidEmail = emailRegex.test(email);

        //Be test and register
        const testEmail = async () => {
            try {
                const body = { email: email };
                const result = await apiHelper('users/register-user', 'POST', body);

                switch (result.responseCode) {
                    case 1:
                        //User exist case
                        handleGAevent('Page-PreRegis-1', 'Click_Login_Email', `Click Login`);
                        ReactPixel.trackCustom('ClickLoginEmail', {
                            action: 'Click_Login_Email',
                            email: email
                        });
                        let userCreate = result.data;
                        if (userCreate.vote === null) {
                            setIsEmailValid(true);
                            fetchDataReward();
                            setpreRegisCurrentPage((prevCount) => prevCount + 1);
                        } else if (userCreate.team === null) {
                            if (teamId !== null && teamId !== undefined && teamId !== 0) {
                                setIsEmailValid(true);
                                fetchDataReward();
                                if (teamId !== null && teamId !== undefined && teamId !== 0) {
                                    setInputedTeamId(teamId);
                                }
                                setpreRegisCurrentPage(5);
                            } else {
                                setIsEmailValid(true);
                                fetchDataReward();
                                setpreRegisCurrentPage(4);
                            }
                        } else {
                            setIsEmailValid(true);
                            fetchDataReward();
                            setTeamId(userCreate.team);
                            setpreRegisCurrentPage(7);
                        }
                        break;
                    case 2:
                        //User not exist and success regis
                        handleGAevent('Page-PreRegis-1', 'Click_Register_Email', `Click Register`);
                        ReactPixel.trackCustom('ClickRegisterEmail', {
                            action: 'Click_Register_Email',
                            email: email
                        });
                        setIsEmailValid(isValidEmail);
                        setpreRegisCurrentPage((prevCount) => prevCount + 1);
                        console.log('Registration success');
                        break;
                    case 0:
                        console.log('Registration failed');
                        setIsLoading(false);
                        setIsEmailValid(false);
                        break;
                }
            } catch (error) {
                setIsEmailValid(false);
                setIsLoading(false);
                console.error('Error:', error);
            }
        };
        if (isValidEmail) {
            testEmail();
        } else {
            setIsEmailValid(false);
            setIsLoading(false);
        }
    }

    const handleSelectCharacter = () => {
        // handleGAevent('Page-PreRegis-2', 'Click_Vote_Character', `Click Vote Character`);
        // ReactPixel.track('SubmitApplication', {
        //     action: 'Click_Vote_Character',
        //     email: email,
        //     vote: preRegisSelectedCharacter,
        //     voteCharacter: CHARACTER_DATA[preRegisSelectedCharacter - 1]
        // });
        handleGAevent('Page-PreRegis-2', 'Click_Vote_Character', `Click Vote Character`);
        ReactPixel.trackCustom('ClickVoteCharacter', {
            action: 'Click_Vote_Character',
            email: email,
            vote: preRegisSelectedCharacter,
            voteCharacter: CHARACTER_DATA[preRegisSelectedCharacter - 1]
        });
        if (preRegisSelectedCharacter !== null) {
            const voteCharacter = async () => {
                try {
                    const body = { email: email, vote: preRegisSelectedCharacter };
                    const result = await apiHelper('users/vote-character', 'POST', body);

                    switch (result.responseCode) {
                        case 1:
                            console.log('Vote Failed');
                            setDisplayText(true);
                            break;
                        case 0:
                            console.log('Vote Success');
                            setDisplayText(false);
                            handleNextPage();
                            break;
                    }
                } catch (error) {
                    // Handle errors if any
                    console.error('Error:', error);
                }
            };
            voteCharacter();
            handleCharacterShareLink();
        } else {
            setDisplayText(true);
        }
    };

    const handleCreateTeam = () => {
        // handleGAevent('Page-PreRegis-4', 'Click_Create_Team', `Click Create Team`);
        // ReactPixel.track('SubmitApplication', {
        //     action: 'Click_Create_Team',
        //     email: email
        // });
        const createTeam = async () => {
            try {
                const body = { email: email };
                const result = await apiHelper('users/create-team', 'POST', body);

                switch (result.responseCode) {
                    case 1:
                        console.log('Email does not exists');
                        break;
                    case 2:
                        console.log('Email does not vote character yet');
                        break;
                    case 3:
                        console.log('User already has team');
                        setRewardAccepted(1);
                        setTeamId(result.data.team);
                        setInviteUrl(`${window.location.href}?invite=${result.data.team}`);
                        break;
                    case 0:
                        console.log('Create Success');
                        setTeamId(result.data.team);
                        setIsTeamCreator(true);
                        setRewardAccepted(1);
                        setInviteUrl(`${window.location.href}?invite=${result.data.team}`);
                        break;
                }
            } catch (error) {
                // Handle errors if any
                console.error('Error:', error);
            }
        };
        createTeam();
        fetchDataReward()
        handleGAevent('Page-PreRegis-4', 'Click_Create_Team', `Click Create Team`);
        ReactPixel.trackCustom('ClickCreateTeam', {
            action: 'Click_Create_Team',
            email: email
        });
        handleNextPage();
    }

    const handleJoinTeam = () => {
        const JoinTeam = async () => {
            try {
                const body = { email: email, team: inputedTeamId };
                const result = await apiHelper('users/join-team', 'POST', body);

                switch (result.responseCode) {
                    case 1:
                        setpreRegisCurrentPage(9);
                        console.log('Team does not exist');
                        break;
                    case 2:
                        setpreRegisCurrentPage(9);
                        console.log('User with this email does not exists');
                        break;
                    case 3:
                        setpreRegisCurrentPage(9);
                        console.log('User already has team');
                        break;
                    case 4:
                        console.log('Team is full');
                        setpreRegisCurrentPage(9);
                        break;
                    case 0:
                        console.log('Join Sucess');
                        setRewardAccepted(result.data.teamMember);
                        setTeamId(inputedTeamId);
                        fetchDataReward();
                        handleNextAmountPage(1);
                        break;
                }
            } catch (error) {
                // Handle errors if any
                console.error('Error:', error);
            }
        };
        JoinTeam();
        // handleGAevent('Page-PreRegis-4', 'Handle_Join_Team', `Handle Join Team`);
        // ReactPixel.track('SubmitApplication', {
        //     action: 'Handle_Join_Team',
        //     email: email,
        //     team: inputedTeamId
        // });
        handleGAevent('Page-PreRegis-4', 'Handle_Join_Team', `Handle Join Team`);
        ReactPixel.trackCustom('HandleJoinTeam', {
            action: 'Handle_Join_Team',
            email: email,
            team: inputedTeamId
        });
    }

    const handleShareButtonClick = (eventName = 'Click_Next_Button') => {
        handleGAevent('Button', eventName, eventName);
        ReactPixel.trackCustom('ShareButtonClick', {
            sharedLink: inviteUrl,
        });
    };

    const handleCharacterShareLink = () => {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        url.search = '';
        url.searchParams.set('id', preRegisSelectedCharacter);
        const updatedUrl = url.href;
        setCharacterInviteUrl(updatedUrl);
    };

    // --  Fetch teamid from url
    useEffect(() => {
        const currentUrl = window.location.href;
        const queryString = currentUrl.split('?')[1];
        const queryParams = new URLSearchParams(queryString);
        const invite = queryParams.get('invite');
        if (invite) {
            setInputedTeamId(invite);
        }
    }, []);

    // --  combine url to team id
    useEffect(() => {
        if (teamId) {
            const currentUrl = new URL(window.location.href);
            currentUrl.search = '';
            currentUrl.searchParams.set('invite', teamId);
            setInviteUrl(currentUrl);
        }
    }, [teamId]);

    return (
        <PopUp isShow={isPreRegisPageShow} handleClosePopup={handleRegisMenuShowClose} containerStyle={containerStylePreRegis} popoutBackgroundUrl={popUpBg}>
            {preRegisCurrentPage === 1 ? (<div className={styles.container}>
                {isLoading && <LoadingIndicator />}
                <form className={styles.formStyle} action="">
                    <div className={styles.EmailInputContainer}>
                        <div className={styles.InputLabel}>Email :</div>
                        <input className={styles.inputForm} name="Email" type="email" value={email} onChange={handleEmailChange} />
                    </div>
                    <div className={`${styles.btnPosition} ${styles.position1}`}>
                        <ImageButton onClick={handleValidateEmail} iconSrc="btn-next.png" altText="Icon" additionalButtonStyle={nextButtonStyle} />
                    </div>
                </form>
                {isEmailValid ? (
                    <div className={styles.preRegisText}>
                        เมื่อคลิกลงทะเบียน แสดงว่าคุณเข้าใจและตกลงตาม<br />
                        <a
                            href="https://sko-cdn.zoltangames.com/preregister/Terms-of-Service.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            ข้อกำหนดและเงื่อนไขการใช้บริการ
                        </a>{' '}
                        และ
                        <a
                            href="https://sko-cdn.zoltangames.com/preregister/Privacy-Policy.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            นโยบายความเป็นส่วนตัว
                        </a>
                    </div>
                ) : (
                    <div className={styles.preRegisText} style={{ color: 'red', justifyContent: 'flex-start' }}>
                        *กรุณาตรวจสอบ email
                    </div>
                )
                }
            </div>
            ) : preRegisCurrentPage === 2 ? (<div className={styles.container}>
                <img src={headerTextSection2Url} alt="header" className={styles.headTextPosition} />
                {displayText ? (
                    <div className={styles.preRegisText} style={{ color: 'red', justifyContent: 'center' }}>
                        *กรุณาเลือกขุนศึก
                    </div>) : ''}

                <form style={formStyle} action="">
                    <div className={styles.charBox}>
                        <label className={styles.characterContainer}>
                            <input type="radio" name="radio" value={1} onChange={handleRadioChange} />
                            <span className={styles.checkmark}></span>
                            <img src={character1Url} alt="header" style={characterSRegisStyle} />
                            <div className={styles.characterSRegisOverlay} />
                        </label>
                        <label className={styles.characterContainer}>
                            <input type="radio" name="radio" value={2} onChange={handleRadioChange} />
                            <span className={styles.checkmark}></span>
                            <img src={character2Url} alt="header" style={characterSRegisStyle} />
                            <div className={styles.characterSRegisOverlay} />
                        </label>
                        <label className={styles.characterContainer}>
                            <input type="radio" name="radio" value={3} onChange={handleRadioChange} />
                            <span className={styles.checkmark}></span>
                            <img src={character3Url} alt="header" style={characterSRegisStyle} />
                            <div className={styles.characterSRegisOverlay} />
                        </label>
                        <label className={styles.characterContainer}>
                            <input type="radio" name="radio" value={4} onChange={handleRadioChange} />
                            <span className={styles.checkmark}></span>
                            <img src={character4Url} alt="header" style={characterSRegisStyle} />
                            <div className={styles.characterSRegisOverlay} />
                        </label>
                    </div>

                </form>
                <div style={{ height: '15%' }}></div>
                <div className={`${styles.btnPosition} ${styles.position2}`}>
                    <ImageButton onClick={handlePreviousPage} iconSrc="btn-previous.png" altText="Icon" additionalButtonStyle={nextButtonStyle} />
                    <ImageButton onClick={handleSelectCharacter} iconSrc="btn-next.png" altText="Icon" additionalButtonStyle={nextButtonStyle} />
                </div>
            </div>
            ) : preRegisCurrentPage === 3 ? (<div className={styles.container}>
                <img src={headerTextSection2Url} alt="header" className={styles.headTextPosition} />
                <div className={styles.smallHeaderPreRegisText}>
                    ท่านเลือกโหวตขุนพล
                </div>
                <img src={characterSelectedUrl} alt="character" className={styles.characterSelectedContainer} />
                <div className={styles.disabledInputContainer}>
                    <input ref={inviteUrlRef} className={`${styles.inputForm} ${styles.disableInputStyle}`} value={characterInviteUrl} disabled />
                    <ImageButton onClick={() => handleCopyText(inviteUrl)} iconSrc="btn_copy.png" altText="Icon" additionalButtonStyle={copyButtonStyle} />
                </div>
                <div className={styles.shareButtonContainer}>
                    <>
                        <Helmet>
                            <meta name="og:image" content={bannerOgUrl}></meta>
                            <meta name="og:title" content="Samkok Origin" />
                            <meta property="og:type" key="og:type" content="website" />
                        </Helmet>
                        <FacebookShareButton url={characterInviteUrl} quote={characterSharingText} onClick={() => handleShareButtonClick("Share Character to Facebook")}>
                            <ImageButton iconSrc="FB-2.png" altText="Icon" additionalButtonStyle={shareButtonStyle} />
                        </FacebookShareButton>
                    </>

                    <>
                        <Helmet>
                            <meta property="og:image" content={bannerOgUrl}></meta>
                            <meta property="og:title" content="Samkok Origin" />
                            <meta property="og:type" key="og:type" content="website" />
                        </Helmet>
                        <FacebookMessengerShareButton appId={FACEBOOK_APP_ID} url={characterInviteUrl} onClick={() => handleShareButtonClick("Share Character to Message")}>
                            <ImageButton iconSrc="messenger.png" altText="Icon" additionalButtonStyle={shareButtonStyle} />
                        </FacebookMessengerShareButton>
                    </>

                    <>
                        <Helmet>
                            <meta property="twitter:image" content={bannerOgUrl}></meta>
                            <meta property="twitter:title" content="Samkok Origin" />
                            <meta property="og:type" key="og:type" content="website" />
                        </Helmet>
                        <TwitterShareButton url={characterInviteUrl} title={characterSharingText} hashtags={hashtag} onClick={() => handleShareButtonClick("Share Character to X")}>
                            <ImageButton iconSrc="X.png" altText="Icon" additionalButtonStyle={shareButtonStyle} />
                        </TwitterShareButton>
                    </>
                    <>
                        <Helmet>
                            <meta property="og:image" content={bannerOgUrl}></meta>
                            <meta property="og:title" content="Samkok Origin" />
                            <meta property="og:type" key="og:type" content="website" />
                        </Helmet>
                        <LineShareButton url={characterInviteUrl} title={characterSharingText} onClick={() => handleShareButtonClick("Share Character to Line")}>
                            <ImageButton iconSrc="Line.png" altText="Icon" additionalButtonStyle={shareButtonStyle} />
                        </LineShareButton>
                    </>
                </div>
                <div className={`${styles.btnPosition} ${styles.position2}`}>
                    <ImageButton onClick={handlePreviousPage} iconSrc="btn-previous.png" altText="Icon" additionalButtonStyle={nextButtonStyle} />
                    <ImageButton onClick={() => handleNextPage("Click_Finish_Voting")} iconSrc="btn-next.png" altText="Icon" additionalButtonStyle={nextButtonStyle} />
                </div>
                <div style={{ height: '3%' }}></div>
            </div>
            ) : preRegisCurrentPage === 4 ? (<div className={styles.container}>
                <div className={styles.bigHeaderPreRegisText}>
                    กิจกรรม จัดทีมล่าขุมทรัพย์ สมบัติคลังหลวง
                </div>
                <div className={styles.teamSelectionContainer}>
                    <img src={createTeamIcon} alt="header" className={styles.teamIcon} />
                    <img onClick={handleCreateTeam} src={createTeamBtn} altText="Icon" style={createButtonStyle} />
                    <div style={{ borderLeft: '1px solid #CEA186', height: '80%' }}></div>
                    <img src={joinTeamIcon} alt="header" className={styles.teamIcon} />
                    <img onClick={() => handleNextAmountPage(2, "Click Fill Team Code")} src={joinTeamBtn} altText="Icon" style={joinButtonStyle} />
                </div>
                <div style={{ height: '15%' }}></div>
            </div>
            ) : preRegisCurrentPage === 5 ? (<div className={styles.container}>
                <div className={styles.bigHeaderPreRegisText}>
                    รหัสชวนเพื่อนของท่านคือ
                </div>
                <div style={{ height: '8%' }}></div>
                <div className={styles.disabledInputContainer}>
                    <input ref={teamCodeRef} className={`${styles.inputForm} ${styles.disableInputStyle}`} value={teamId} disabled />
                    <ImageButton onClick={() => handleCopyText(teamId)} iconSrc="btn_copy.png" altText="Icon" additionalButtonStyle={copyButtonStyle} />
                </div>
                <div className={styles.disabledInputContainer}>
                    <input className={`${styles.inputForm} ${styles.disableInputStyle}`} value={inviteUrl} disabled />
                    <ImageButton onClick={() => handleCopyText(inviteUrl)} iconSrc="btn_copy.png" altText="Icon" additionalButtonStyle={copyButtonStyle} />
                </div>
                <div className={`${styles.btnPosition} ${styles.position1}`}>
                    <ImageButton onClick={() => { handleNextAmountPage(2) }} iconSrc="btn-next.png" altText="Icon" additionalButtonStyle={nextButtonStyle} />
                </div>
                <div style={{ height: '15%' }}></div>
            </div>
            ) : preRegisCurrentPage === 6 ? (<div className={styles.container}>
                <div className={`${styles.bigHeaderPreRegisText} ${styles.bigHeaderPositionPage6}`}>
                    กรอกรหัสชวนเพื่อน
                </div>
                <div className={styles.disabledInputContainer}>
                    <input ref={inputedTeamIdRef} className={`${styles.inputForm} ${styles.disableInputStyle}`} onChange={handleInputChange} value={inputedTeamId} />
                    {/* {!isSafari() && (<ImageButton onClick={() => handlePasteText(inputedTeamIdRef)} iconSrc="btn-paste.png" altText="Icon" additionalButtonStyle={copyButtonStyle} />)} */}
                </div>
                <div className={`${styles.btnPosition} ${styles.position1}`}>
                    <ImageButton onClick={() => { handlePreviousAmountPage(2) }} iconSrc="btn-previous.png" altText="Icon" additionalButtonStyle={nextButtonStyle} />
                    <ImageButton onClick={handleJoinTeam} iconSrc="btn-next.png" altText="Icon" additionalButtonStyle={nextButtonStyle} />
                </div>
                <div style={{ height: '10%' }}></div>
            </div>
            ) : preRegisCurrentPage === 7 ? (<div className={styles.container}>
                <div className={styles.contentHeader} style={frameHeaderContainer}>
                    ชวนเพื่อนสำเร็จแล้ว
                    <div className={styles.count}>{isTeamCreator ? rewardAccepted - 1 : rewardAccepted}</div>
                    คน
                </div>
                <div className={styles.rewardContainer}>
                    <div className={styles.reward}>
                        <div className={styles.rewardIcon}>
                            <img className={styles.rewardBg} src={rewardBg} />
                            <img className={styles.icon} src={rewardBox1} alt={'section-2'} />
                            <img className={styles.accepted} src={rewardAccept} alt={'section-2'}
                                style={{ opacity: 1 <= rewardAccepted ? '0.8' : '0' }}
                            />
                        </div>
                        <div className={styles.rewardText}>
                            สร้างรหัส <br />
                            ชวนเพื่อน
                        </div>
                    </div>
                    <div className={styles.reward}>
                        <div className={styles.rewardIcon}>
                            <img className={styles.rewardBg} src={rewardBg} />
                            <img className={styles.icon} src={rewardBox1} alt={'section-2'} />
                            <img className={styles.accepted} src={rewardAccept} alt={'section-2'}
                                style={{ opacity: 2 <= rewardAccepted ? '0.8' : '0' }}
                            />
                        </div>
                        <div className={styles.rewardText}>
                            เชิญเพื่อน<br />
                            สำเร็จ 1 คน</div>

                    </div>
                    <div className={styles.reward}>
                        <div className={styles.rewardIcon}>
                            <img className={styles.rewardBg} src={rewardBg} />
                            <img className={styles.icon} src={rewardBox2} alt={'section-2'} />
                            <img className={styles.accepted} src={rewardAccept} alt={'section-2'}
                                style={{ opacity: 3 <= rewardAccepted ? '0.8' : '0' }}
                            />
                        </div>
                        <div className={styles.rewardText}>
                            เชิญเพื่อน<br />
                            สำเร็จ 2 คน</div>

                    </div>
                    <div className={styles.reward}>
                        <div className={styles.rewardIcon}>
                            <img className={styles.rewardBg} src={rewardBg} />
                            <img className={styles.icon} src={rewardBox2} alt={'section-2'} />
                            <img className={styles.accepted} src={rewardAccept} alt={'section-2'}
                                style={{ opacity: 4 <= rewardAccepted ? '0.8' : '0' }}
                            />
                        </div>
                        <div className={styles.rewardText}>
                            เชิญเพื่อน<br />
                            สำเร็จ 3 คน
                        </div>

                    </div>
                </div>
                <div className={styles.disabledInputContainer}>
                    <input className={`${styles.inputForm} ${styles.disableInputStyle}`} value={teamId} disabled />
                    <ImageButton onClick={() => handleCopyText(teamId)} iconSrc="btn_copy.png" altText="Icon" additionalButtonStyle={copyButtonStyle} />
                </div>
                <div className={styles.shareButtonContainer}>
                    <>
                        <Helmet>
                            <meta property="og:image" content={bannerOgUrl}></meta>
                            <meta property="og:title" content="Samkok Origin" />
                            <meta property="og:type" key="og:type" content="website" />
                        </Helmet>
                        <FacebookShareButton url={inviteUrl} quote={titleToShare} onClick={() => handleShareButtonClick("Share Code to Facebook")}>
                            <ImageButton iconSrc="FB-2.png" altText="Icon" additionalButtonStyle={shareButtonStyle} />
                        </FacebookShareButton>
                    </>

                    <>
                        <Helmet>
                            <meta property="og:image" content={bannerOgUrl}></meta>
                            <meta property="og:title" content="Samkok Origin" />
                            <meta property="og:type" key="og:type" content="website" />
                        </Helmet>
                        <FacebookMessengerShareButton appId={FACEBOOK_APP_ID} url={inviteUrl} onClick={() => handleShareButtonClick("Share Code to Message")}>
                            <ImageButton iconSrc="messenger.png" altText="Icon" additionalButtonStyle={shareButtonStyle} />
                        </FacebookMessengerShareButton>
                    </>

                    <>
                        <Helmet>
                            <meta property="twitter:image" content={bannerOgUrl}></meta>
                            <meta property="twitter:title" content="Samkok Origin" />
                            <meta property="og:type" key="og:type" content="website" />
                        </Helmet>
                        <TwitterShareButton url={inviteUrl} title={titleToShare} hashtags={hashtag} onClick={() => handleShareButtonClick("Share Code to X")}>
                            <ImageButton iconSrc="X.png" altText="Icon" additionalButtonStyle={shareButtonStyle} />
                        </TwitterShareButton>
                    </>
                    <>
                        <Helmet>
                            <meta property="og:image" content={bannerOgUrl}></meta>
                            <meta property="og:title" content="Samkok Origin" />
                            <meta property="og:type" key="og:type" content="website" />
                        </Helmet>
                        <LineShareButton url={inviteUrl} title={titleToShare} onClick={() => handleShareButtonClick("Share Code to Line")}>
                            <ImageButton iconSrc="Line.png" altText="Icon" additionalButtonStyle={shareButtonStyle} />
                        </LineShareButton>
                    </>
                </div>
                <div className={`${styles.btnPosition} ${styles.position1}`}>
                    <ImageButton onClick={handleRegisMenuShowClose} iconSrc="btn-next.png" altText="Icon" additionalButtonStyle={nextButtonStyle} />
                </div>
                <div style={{ height: '15%' }}></div>
            </div>
            ) : preRegisCurrentPage === 9 ? (<div className={styles.container2}>
                <div className={styles.infoContainer}><img className={styles.character} src={character} alt={'maskot'} /></div>
                {/* <img className={styles.character} src={character} alt={'maskot'} /> */}
                <img className={styles.infoText} src={infoText} alt={'info-text'} />
                <div className={`${styles.btnPosition} ${styles.position1}`}>
                    <ImageButton onClick={() => { handlePreviousAmountPage(3) }} iconSrc="btn-previous.png" altText="Icon" additionalButtonStyle={nextButtonStyle} />
                </div>
                <div style={{ height: '10%' }}></div>
            </div>) : ''
            }
        </PopUp >
    )
}

export default PreRegisPopUp