import React, { useState,useRef } from 'react';
import './PopUp.scss';
import ImageButton from './Button';

const PopUp = ({ children , isShow, handleClosePopup, containerStyle , popoutBackgroundUrl, popupwidth = '80%'}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);


  const popoutBackground = {
    backgroundImage: `url(${popoutBackgroundUrl})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: popupwidth,
  };

  const buttonStyle = {
    width : '2rem',
    height : '2rem',
    position : 'absolute',
    top : '10%',
    right : '10%'
  };
  const handlePlay = () => {
    if (!isPlaying) {
        setIsPlaying(true);
    }
  };

  const handleClose = () => {
    setIsPlaying(false);
  };

  return (
    <div className= {`overlayBackground ${isShow ? 'show' : ''}`}>
        <div className="popOutContainer" style={popoutBackground}>
          <ImageButton  
            onClick={handleClosePopup} 
            iconSrc="btn_close.png" altText="Icon" 
            additionalButtonStyle={buttonStyle} 
          />
          <div className="popUpContent" style={containerStyle}>
            {children}
          </div>
        </div>

    </div>

  );
};

export default PopUp;