import styles from './PcSection4.module.scss';
import { useState } from 'react';
import ImageButton from '../../components/Button';
import handleGAevent from '../../helper/analytic';
import ReactPixel from 'react-facebook-pixel';
import InfoCarousel from '../../components/InfoCarousel';

const PcSection4 = ({cdn, sectionRef, contentHeight }) => {
    // -- headtext
    const headtext = `https://sko-cdn.zoltangames.com/preregister/img-headtext-page-5.png`;

    // -- Info Carousel state
    const [isInfoCarouselShow, setIsInfoCarouselShow] = useState(false);
    const [infoCarouselIndex, setInfoCarouselIndex] = useState(0);

    // -- btn
    const btnUrl = 'btn-system-info.png'

    // -- btn style
    const buttonStyle = {
        width: '100%',
        height: '100%',
    };

    // -- Info Carousel function
    const handleInfoCarouselShowOpen = (index) => {
        setInfoCarouselIndex((prevIndex) => {
            return index;
        });
        setIsInfoCarouselShow(true);
        //setIsMenuShow(false); TODO
        handleGAevent('Page-5', 'Open_Info_Carousel', `Open Info Carousel`);
        ReactPixel.trackCustom('OpenInfoCarousel');
    }
    const handleInfoCarouselShowClose = () => {
        setIsInfoCarouselShow(false);
        //setIsMenuShow(true); TODO
        handleGAevent('Page-5', 'Close_Info_Carousel', `Close Info Carousel`);
        ReactPixel.trackCustom('CloseInfoCarousel');
    }


    return (
        <div className={styles.section} ref={sectionRef} style={{ height: `${1 * contentHeight}px` }}>
            <InfoCarousel cdn={cdn} isShow={isInfoCarouselShow} handleClosePopup={handleInfoCarouselShowClose} selectedIndex={infoCarouselIndex} setSelectedIndex={setInfoCarouselIndex}></InfoCarousel>
            <img className={styles.headtext} src={`${headtext}`} alt={'section-2'} />
            <div className={styles.systemInfoContainer}>
                <div className={styles.systemInfoContent}>
                    <img src="https://sko-cdn.zoltangames.com/preregister/img-system-info-1.png" alt="Normal" className={styles.defaultImage} />
                    <img src="https://sko-cdn.zoltangames.com/preregister/img-system-info-1-hover.png" alt="Hover" className={styles.hoverImage} />
                    <div className={styles.hoverButton}>
                        <ImageButton
                            onClick={() => handleInfoCarouselShowOpen(0)}
                            iconSrc={btnUrl}
                            altText={'info button'}
                            additionalButtonStyle={buttonStyle}
                        />
                    </div>
                </div>
                <div className={styles.systemInfoContent}>
                    <img src="https://sko-cdn.zoltangames.com/preregister/img-system-info-2.png" alt="Normal" className={styles.defaultImage} />
                    <img src="https://sko-cdn.zoltangames.com/preregister/img-system-info-2-hover.png" alt="Hover" className={styles.hoverImage} />
                    <div className={styles.hoverButton}>
                        <ImageButton
                            onClick={() => handleInfoCarouselShowOpen(1)}
                            iconSrc={btnUrl}
                            altText={'info button'}
                            additionalButtonStyle={buttonStyle}
                        />
                    </div>
                </div>
                <div className={styles.systemInfoContent}>
                    <img src="https://sko-cdn.zoltangames.com/preregister/img-system-info-3.png" alt="Normal" className={styles.defaultImage} />
                    <img src="https://sko-cdn.zoltangames.com/preregister/img-system-info-3-hover.png" alt="Hover" className={styles.hoverImage} />
                    <div className={styles.hoverButton}>
                        <ImageButton
                            onClick={() =>handleInfoCarouselShowOpen(2)}
                            iconSrc={btnUrl}
                            altText={'info button'}
                            additionalButtonStyle={buttonStyle}
                        />
                    </div>
                </div>
                <div className={styles.systemInfoContent}>
                    <img src="https://sko-cdn.zoltangames.com/preregister/img-system-info-4.png" alt="Normal" className={styles.defaultImage} />
                    <img src="https://sko-cdn.zoltangames.com/preregister/img-system-info-4-hover.png" alt="Hover" className={styles.hoverImage} />
                    <div className={styles.hoverButton}>
                        <ImageButton
                            onClick={() => handleInfoCarouselShowOpen(3)}
                            iconSrc={btnUrl}
                            altText={'info button'}
                            additionalButtonStyle={buttonStyle}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PcSection4;
