import styles from './PcSection2.module.scss';
import { useState, useEffect } from 'react';
import ImageButton from '../../components/Button';
import handleGAevent from '../../helper/analytic';
import ReactPixel from 'react-facebook-pixel';
import PopUp from '../../components/PopUp';
import { START_DATE, END_DATE } from '../../consts/dateData';

const PcSection2 = ({
    sectionRef,
    contentHeight,
    setIsPreRegisPageShow,
    rewardAccepted,
    isTeamCreator,
    setIsMenuShow
}) => {
    // -- Character and headtext
    const headtext = `https://sko-cdn.zoltangames.com/preregister/img-headtext-page-3.png`;
    // -- Reward icon
    const rewardBox1 = 'https://sko-cdn.zoltangames.com/preregister/reward_box_1.png';
    const rewardBox2 = 'https://sko-cdn.zoltangames.com/preregister/reward_box_2.png';
    const rewardAccept = 'https://sko-cdn.zoltangames.com/preregister/reward_accept.png';
    const popUpBg = `https://sko-cdn.zoltangames.com/preregister/bg-pop-up.png`;

    // -- Frame Background
    const frameContainer = {
        backgroundImage: `url(https://sko-cdn.zoltangames.com/preregister/bg-frame-page-3.png)`
    };
    const frameHeaderContainer = {
        backgroundImage: `url(https://sko-cdn.zoltangames.com/preregister/bg-text-page-3.png)`
    };
    const rewardIconContainer = {
        backgroundImage: `url(https://sko-cdn.zoltangames.com/preregister/bg-reward-page-3.png)`
    };
    const containerStyleInfo = {
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        color: '#79694D',
        fontFamily: 'Noto Sans Thai',
        fontWeight: '600',
        whiteSpace: 'pre-line',
        marginLeft: '10%',
        fontSize: '1.7rem'
    }

    // -- Info Popup state
    const [isInfoPopupShow, setIsInfoPopupShow] = useState(false);

    // -- Btn Style
    const validateButtonStyle = {
        width: '15rem',
        height: '100%',
        marginRight: '10px'
    };
    const infoButton2Style = {
        height: '100%',
    };

    // -- Info button function
    const handleInfoPopupOpen = () => {
        setIsInfoPopupShow(true);
        setIsMenuShow(false);
        handleGAevent('Page-2', 'Open_Info_Pop_Up_Page_3', `Open Info Pop Up Page 3`);
        ReactPixel.trackCustom('OpenInfoPopUpPage2');
    }
    const handleInfoPopupClose = () => {
        setIsInfoPopupShow(false);
        setIsMenuShow(true);
        handleGAevent('Page-3', 'Close_Info_Pop_Up_Page_3', `Close Info Pop Up Page 3`);
        ReactPixel.trackCustom('CloseInfoPopUpPage3');
    }

    // -- Pre register button function
    const handleRegisMenuShowOpenPage3 = () => {
        setIsPreRegisPageShow(true);
        handleGAevent('Page-1', 'Click_Join_Invite_Friend_Event', `Click Join Invite Friend Event`);
        ReactPixel.trackCustom('OpenPreRegisPage');
    }

    return (
        <div className={styles.section} ref={sectionRef} style={{ height: `${1 * contentHeight}px` }}>
            <PopUp isShow={isInfoPopupShow} handleClosePopup={handleInfoPopupClose} containerStyle={containerStyleInfo} popoutBackgroundUrl={popUpBg}>
                ระยะเวลากิจกรรม <br />
                เริ่มต้น : {START_DATE}<br />
                สิ้นสุด : {END_DATE}<br />
                ขั้นตอนการชวนเพื่อน<br />
                <br />
                ผู้ใช้งานต้องทำการลงทะเบียนล่วงหน้าด้วย E-mail<br />
                ผู้ใช้งานสามารถคลิกที่ลิงก์ URL เพื่อทำการ Copy ลิงก์กิจกรรม และส่งไปชวนเพื่อนได้<br />
                เมื่อผู้ถูกเชิญคลิกที่ "ลิงก์เชิญชวน" ที่คุณส่งแล้วจะต้องทำการลงทะเบียนด้วย E-mail ให้เรียบร้อย ระบบจึงจะนับว่าคุณชวนเพื่อนสำเร็จ<br />
                เมื่อผู้ใช้งานเชิญเพื่อนได้สำเร็จ ผู้เชิญจะได้รับรางวัลตามจำนวนเพื่อนที่ผู้เชิญเชิญได้ดังนี้<br />
                เชิญครบ 1 คน ผู้เชิญและทีมจะได้รับ กระดานพยากรณ์ 2x<br />
                เชิญครบ 2 คน ผู้เชิญและทีมจะได้รับ กระดานพยากรณ์ 3x<br />
                เชิญครบ 3 คน ผู้เชิญและทีมจะได้รับ กระดานพยากรณ์ 3x<br />
                เงื่อนไขการเชิญ และ การตอบรับคำเชิญ<br />
                ผู้ใช้งานสามารถเชิญเพื่อนได้ทั้งหมด 3 คน<br />
                หากผู้ใช้เลือกลงทะเบียนล่วงหน้าจาก URL หรือรหัสของเพื่อนเรียบร้อย จะไม่สามารถสร้าง URL ใหม่เพื่อเชิญเพื่อนได้<br />
                ผู้ใช้งานแต่ละบัญชี สามารถตอบรับคำเชิญจากผู้อื่นได้เพียง 1 ครั้ง เท่านั้น<br />
                ผู้ที่เชิญจะสามารถได้รับรางวัลจากการชวนเพื่อนสูงสุดเพียง 3 รางวัล เท่านั้น<br />
                ทีมงานขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า<br />
            </PopUp>
            <img className={styles.headtext} src={`${headtext}`} alt={'section-2'} />
            <div className={styles.btnContainer}>
                <ImageButton onClick={handleRegisMenuShowOpenPage3} iconSrc="btn-pre-regis-2.png" altText="Icon" additionalButtonStyle={validateButtonStyle} />
                <ImageButton onClick={handleInfoPopupOpen} iconSrc="btn-info.png" altText="Icon" additionalButtonStyle={infoButton2Style} />
            </div>
            <div className={styles.contentFrame} style={frameContainer}>
                <div className={styles.contentHeader} style={frameHeaderContainer}>
                    ชวนเพื่อนสำเร็จแล้ว
                    <div className={styles.count}>{isTeamCreator ? rewardAccepted - 1 : rewardAccepted}</div>
                    คน
                </div>
                <div className={styles.rewardContainer}>
                    <div className={styles.reward}>
                        <div className={styles.rewardIcon} style={rewardIconContainer}>
                            <img className={styles.icon} src={rewardBox1} alt={'section-2'} />
                            <img className={styles.accepted} src={rewardAccept} alt={'section-2'}
                                style={{ opacity: 1 <= rewardAccepted ? '0.8' : '0' }}
                            />
                        </div>
                        สร้างรหัส <br />
                        ชวนเพื่อน
                    </div>
                    <div className={styles.reward}>
                        <div className={styles.rewardIcon} style={rewardIconContainer}>
                            <img className={styles.icon} src={rewardBox1} alt={'section-2'} />
                            <img className={styles.accepted} src={rewardAccept} alt={'section-2'}
                                style={{ opacity: 2 <= rewardAccepted ? '0.8' : '0' }}
                            />
                        </div>
                        เชิญเพื่อน<br />
                        สำเร็จ 1 คน
                    </div>
                    <div className={styles.reward}>
                        <div className={styles.rewardIcon} style={rewardIconContainer}>
                            <img className={styles.icon} src={rewardBox2} alt={'section-2'} />
                            <img className={styles.accepted} src={rewardAccept} alt={'section-2'}
                                style={{ opacity: 3 <= rewardAccepted ? '0.8' : '0' }}
                            />
                        </div>
                        เชิญเพื่อน<br />
                        สำเร็จ 2 คน
                    </div>
                    <div className={styles.reward}>
                        <div className={styles.rewardIcon} style={rewardIconContainer}>
                            <img className={styles.icon} src={rewardBox2} alt={'section-2'} />
                            <img className={styles.accepted} src={rewardAccept} alt={'section-2'}
                                style={{ opacity: 4 <= rewardAccepted ? '0.8' : '0' }}
                            />
                        </div>
                        เชิญเพื่อน<br />
                        สำเร็จ 3 คน
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PcSection2;
