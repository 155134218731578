import axios from 'axios';

const apiHelper = async (path, method = 'GET', data = null, query = null) => {
  try {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const url = `${baseURL}/${path}`;
    const response = await axios({
      url,
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      data,
      params: query
    });
    return response.data;
  } catch (error) {
    console.error('Error :' +error);
    throw error;
  }
};

export default apiHelper;